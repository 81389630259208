<template>
  <v-app
    id="dashboard-view"
    :dark="theme==='dark'"
    :light="theme==='light'"
    :class="{'dashboard_bg_dark' : theme==='dark' , 'dashboard_bg_light' : theme==='light'}"
    :style="'padding-left: '+shrink+'px;'"
  >
    <!-- <core-filter /> -->

    <core-toolbar />

    <core-drawer />

    <v-content
      :style="'padding-left: 0px;'">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-content>
    <!-- <core-footer v-if="$route.meta.name !== 'Maps'" /> -->
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DashboardView',
  metaInfo () {
    return {
      title: 'ASUS QAMP'
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  methods: {
  }
}
</script>

<style>
  html {
    overflow-x: auto!important;
    overflow-y: auto!important;
  }
  *:not(i), *:not(i)::before, *:not(i)::after {
    font-family: '\5FAE\8EDF\6B63\9ED1\9AD4'!important; /*微軟正黑體*/
  }
  .v-content__wrap {
    /* width: 1640px; */
  }
  .v-table__overflow {
      overflow-x: visible!important;
      overflow-y: hidden!important;
  }
  .flex_width_auto {
    flex-basis: auto!important;
  }

  .flex_table_width_auto table.v-table{
    width: 100%!important;
  }
  .fit_content>.v-card{
    width: fit-content;
  }
  .v-dialog__content {
    left: auto!important;
    z-index: 202;
  }

  .v-dialog__content>.v-dialog {
    margin: 0;
    left: 0;
    position: absolute;
    box-shadow: none;
  }

  .v-dialog__content>div>div>div>div.v-alert {
    /*margin-top: -35px!important;*/
  }

  .dashboard_bg_dark {
    -webkit-background: repeating-linear-gradient(135deg,RGBA(10,10,10,1) 20%,RGBA(40,40,40,1) 50%,  RGBA(10,10,10,1) 80%)!important;
    -moz-background: repeating-linear-gradient(135deg,RGBA(10,10,10,1) 20%,RGBA(40,40,40,1) 50%,  RGBA(10,10,10,1) 80%)!important;
    -o-background: repeating-linear-gradient(135deg,RGBA(10,10,10,1) 20%,RGBA(40,40,40,1) 50%,  RGBA(10,10,10,1) 80%)!important;
    -ms-background: repeating-linear-gradient(135deg,RGBA(10,10,10,1) 20%,RGBA(40,40,40,1) 50%,  RGBA(10,10,10,1) 80%)!important;
    background: repeating-linear-gradient(135deg,RGBA(10,10,10,1) 20%,RGBA(40,40,40,1) 50%,  RGBA(10,10,10,1) 80%)!important;
  }

  .dashboard_bg_light {
    -webkit-background: repeating-linear-gradient(135deg,RGBA(250,250,250,1) 20%,RGBA(220,220,220,1) 50%,  RGBA(250,250,250,1) 80%)!important;
    -moz-background: repeating-linear-gradient(135deg,RGBA(250,250,250,1) 20%,RGBA(220,220,220,1) 50%,  RGBA(250,250,250,1) 80%)!important;
    -o-background: repeating-linear-gradient(135deg,RGBA(250,250,250,1) 20%,RGBA(220,220,220,1) 50%,  RGBA(250,250,250,1) 80%)!important;
    -ms-background: repeating-linear-gradient(135deg,RGBA(250,250,250,1) 20%,RGBA(220,220,220,1) 50%,  RGBA(250,250,250,1) 80%)!important;
    background: repeating-linear-gradient(135deg,RGBA(250,250,250,1) 20%,RGBA(220,220,220,1) 50%,  RGBA(250,250,250,1) 80%)!important;
  }
  .theme--light .v-c3-chart>.v-card__text {
    -webkit-background-image: linear-gradient(0deg, #FFF 0%, #dff 100%)!important;
    -moz-background-image: linear-gradient(0deg, #FFF 0%, #dff 100%)!important;
    -o-background-image: linear-gradient(0deg, #FFF 0%, #dff 100%)!important;
    -ms-background-image: linear-gradient(0deg, #FFF 0%, #dff 100%)!important;
    background-image: linear-gradient(0deg, #FFF 0%, #dff 100%)!important;
  }
  .theme--dark .v-c3-chart>.v-card__text {
    -webkit-background-image: linear-gradient(0deg, #424242 0%, #422121 100%)!important;
    -moz-background-image: linear-gradient(0deg, #424242 0%, #422121 100%)!important;
    -o-background-image: linear-gradient(0deg, #424242 0%, #422121 100%)!important;
    -ms-background-image: linear-gradient(0deg, #424242 0%, #422121 100%)!important;
    background-image: linear-gradient(0deg, #424242 0%, #422121 100%)!important;
  }

  .area-shrink {
    overflow: hidden !important;
    /* transition: 0.5s !important; */
    /* max-height: 100% !important; */
  }
  .area-shrink.area-shrink-active {
    max-height: 0 !important;
    /* transform: scale(0); */
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .area-shrink-active-icon {
    margin-top: -0.5rem;
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    transform:rotate(180deg);
  }
  .card-shrink>.v-card__text {
    overflow: hidden !important;
  }
  .flex {
    padding: 9px 12px !important;
  }
  .flex-small {
    padding: 0 12px !important;
  }
  .flex-small-0 {
    padding: 0 !important;
  }
  .v-flex__first {
    margin-top: 60px !important;
  }
  .c3-chart {
    /*padding-top: 0 !important;*/
  }
  .c3-chart .v-alert--notification {
    padding: 5px 15px !important;
    margin-top: -30px!important;
  }
  .v-card {
    margin-bottom: 0px !important;
    margin-top: 10px !important;
  }
  .v-card .title {
    font-weight: bold!important;
  }

  .v-card__first {
    margin-top: 35px !important;
  }

  .v-card__text:not(.v-c3-chart .v-card__text) {
    padding-top: 0 !important;
  }
  .card-padding .v-card__text {
    padding-top: 16px !important;
    padding-bottom: 0 !important;
  }
  .card-shrink.card-shrink-active>.v-card__text {
    max-height: 0 !important;
  }
  .card-shrink.card-shrink-active.card-shrink-70>.v-card__text {
    max-height: 70px !important;
  }
  .card-shrink.card-shrink-active.card-shrink-115>.v-card__text {
    max-height: 115px !important;
  }
  .card-shrink.card-shrink-active.plus>.v-card__text {
    padding: 0 !important;
  }

  .card-shrink>.v-card__text>.v-icon {
    position: absolute;
    top: -1rem;
    right: 2rem;
    background: #fff;
    color: rgba(0,0,0,.75);
    padding: 0.25rem;
    border-radius: 100%;
  }
  .card-shrink-active-icon {
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    transform:rotate(180deg);
  }
  .card-shrink>.v-card__text>.card-memo-1 {
    position: absolute;
    top: -1rem;
    right: 16rem;
    font-weight: bold;
    padding: 0.25rem;
  }
  .card-shrink>.v-card__text>.card-memo-2 {
    position: absolute;
    top: -1rem;
    right: 2rem;
    font-weight: bold;
    padding: 0.25rem;
  }
  .card-mix>.v-card__text>.mix-btn {
    position: absolute;
    top: -1.25rem;
    right: 2rem;
    padding: 0.5rem;
  }
  .card-mix>.v-card__text>.mix-list {
    position: absolute;
    top: -2.25rem;
    right: 2rem;
    padding: 0rem;
    padding-top: 12px;
  }
  .card-mix>.v-card__text>.mix-input {
    position: absolute;
    top: -2.25rem;
    right: 2rem;
    padding: 0rem;
    padding-top: 12px;
  }
  .table-overflow>.v-card__text{
    overflow-x: auto;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .v-list__tile {
    height: auto !important;
  }
  .v-text-field__details-none .v-text-field__details {
    display: none !important;
  }

  .demarcation {
    display: flex !important;
    margin-bottom: -30px;
  }
  .demarcation>span {
    margin-left: 1rem !important;
    font-size: 1.5625rem !important;
    line-height: 2rem !important;
    white-space:nowrap !important;
  }
  .demarcation>.v-icon {
    margin-right: 1rem !important;
    float: right !important;
    cursor: pointer !important;
  }
  .demarcation>hr {
    flex-basis: 100% !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .theme--light .demarcation>hr {
    border-top: 1px solid rgba(0, 0, 0, .75) !important;
  }
  .theme--dark .demarcation>hr {
    border-top: 1px solid rgba(255, 255, 255, .75) !important;
  }

  .v-card .v-offset .v-card--material__header.v-card {
    padding: 2px 15px;
    padding-top: 7px !important;
  }

  .v-card--material-stats.v-card .v-offset .v-card {
    max-width: 85px;
    max-height: 85px;
    line-height: 0;
    padding: 10px !important;
  }

  .v-c3-chart .theme--light.v-icon {
      color: #fff;
  }

  .v-c3-chart .theme--dark.v-icon {
      color: #fff;
  }

  .theme--light .v-input__slot label, .theme--light .v-input__slot input {
    caret-color: #000!important;
  }
  .theme--dark .v-input__slot label, .theme--dark .v-input__slot input {
    caret-color: #aaa!important;
  }

  .theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat){
    background-color: #212121;
  }
  .theme--dark.v-btn:not(.v-btn--icon):not(.v-btn--flat){
    background-color: #212121;
  }

  .theme--light .v-text-field__slot .v-label, .theme--light.v-input:not(.v-input--is-disabled) input, .theme--light.v-input:not(.v-input--is-disabled) textarea {
    color: rgba(0,0,0,1)!important;
  }
  .theme--dark .v-text-field__slot .v-label, .theme--dark.v-input:not(.v-input--is-disabled) input, .theme--dark.v-input:not(.v-input--is-disabled) textarea {
    color: #fff!important;
  }

  .theme--dark .v-input--is-focused .v-icon {
    color: #f44336!important;
  }

  .theme--light .v-input--is-focused .v-icon {
    color: #2196f3!important;
  }

  .card-mix .v-input--is-focused.mix-list .v-icon {
    color: #ffffff!important;
  }
  .card-mix .mix-list .v-input__slot:after {
    border-color: #ffffff!important;
  }

  .card-mix .v-input--is-focused.mix-input .v-icon {
    color: #ffffff!important;
  }
  .card-mix .mix-input .v-input__slot:after {
    border-color: #ffffff!important;
  }
  .card-mix .mix-input .v-text-field__slot .v-label {
    color: #ffffff99!important;
  }

  .v-input--checkbox .v-label {
    margin: auto;
  }

  .cfr input[type="number"] {
    text-align: center;
  }
  .cfr .v-input--selection-controls .v-input__control {
    width: 100% !important;
    height: 32px;
  }
  .cfr .v-input--selection-controls .v-input__control .v-input__slot {
    margin: 0 !important;
    display: block;
  }
  .cfr .v-input--selection-controls .v-input__control .v-input__slot .v-input--radio-group__input {
    padding: 4px 0;
  }
  .cfr .v-input--selection-controls .v-input__control .v-input__slot .v-input--radio-group__input .v-input--selection-controls__ripple {
    height: auto;
  }
  .cfr .v-input--selection-controls .v-input__control .v-input__slot .v-input--radio-group__input label {
    margin: 0 !important;
    font-weight: 400;
  }
  .cfr .v-input--selection-controls .v-input__control .v-input__slot .v-input--radio-group__input label.active {
    left: -8px;
    top: 0px;
    position: absolute;
    transform: translateY(-25px) scale(.75);
  }
  .cfr .accent--text {
    color: #f44336!important;
    caret-color: #f44336!important;
  }

  .theme--light table.statistic-table {
    width: 100%;
    margin: 15px 0;
    background-color: #ffffff;
    color: rgba(0,0,0,.87);;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 2px;
    border-collapse: collapse;
    border-spacing: 0;
  }
  .theme--light table.statistic-table>thead>tr {
    border-bottom: 1px solid hsla(0,0%,0%,.12);
    height: 36px;
    padding: 0 10px;
    word-break: break-word;
    white-space: normal;
    box-sizing: border-box;
  }
  .theme--light table.statistic-table>thead>tr>th {
    padding: 0 10px;
    user-select: none;
    color: #BA2200;
    font-weight: bold;
    word-break: break-word;
    white-space: normal;
    text-align: left;
    font-size: 16px;
  }
  .theme--light table.statistic-table>tbody>tr {
    height: 36px;
    border-top: 1px solid hsla(0,0%,0%,.12);
  }
  .theme--light table.statistic-table>tbody>tr:nth-of-type(odd) {
    background: rgba(0, 0, 0, .1);
  }
  .theme--light table.statistic-table>tbody>tr.problem-total {
    background: #eff6f6;
  }
  .theme--light table.statistic-table>tbody>tr.target {
    background: #eff6f6;
  }
  .theme--light table.statistic-table>tbody>tr.others {
    background: rgba(0, 0, 0, .1);
  }
  .theme--light table.statistic-table>tbody>tr.total {
    background: rgba(0, 0, 0, .1);
  }
  .theme--light .row-problem-total table>tbody>tr:nth-last-of-type(1){
    background: #eff6f6;
  }
  .theme--light table.statistic-table>tbody>tr>td {
    height: auto;
    padding: 0 10px;
    word-break: break-word;
    white-space: normal;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 13px;
  }
  .theme--light table.statistic-table>tbody>tr>td.problem-module {
    background: rgba(0, 0, 0, .1);
  }
  .theme--light table.statistic-table>tbody>tr>td.table-module-warning {
    background-color: #a9e6e6;
  }
  .theme--light table.statistic-table:not(.icon-white)>tbody>tr>td i{
    color: #ff9800;
  }
  .theme--light table.statistic-table>tbody>tr>td.table-text-zero {
    color: #cccccc;
  }

  .theme--dark table.statistic-table {
    width: 100%;
    margin: 15px 0;
    background-color: #424242;
    color: #fff;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 2px;
    border-collapse: collapse;
    border-spacing: 0;
  }
  .theme--dark table.statistic-table>thead>tr {
    border-bottom: 1px solid #666666;
    height: 36px;
    padding: 0 10px;
    word-break: break-word;
    white-space: normal;
    box-sizing: border-box;
  }
  .theme--dark table.statistic-table>thead>tr>th {
    padding: 0 5px;
    user-select: none;
    color: #ff9800;
    font-weight: bold;
    word-break: break-word;
    white-space: normal;
    text-align: left;
    font-size: 16px;
    vertical-align: bottom;
  }
  .theme--dark table.statistic-table>tbody>tr {
    height: 36px;
    border-top: 1px solid hsla(0,0%,100%,.12);
  }
  .theme--dark table.statistic-table>tbody>tr:nth-of-type(odd) {
    background: rgba(0, 0, 0, .2);
  }
  .theme--dark table.statistic-table>tbody>tr.problem-total {
    background: rgba(255, 0, 0, .2);
  }
  .theme--dark table.statistic-table>tbody>tr.problem-total>td {
    background-color: #663333 !important;
  }
  .theme--dark table.statistic-table>tbody>tr.target {
    background: rgba(255, 0, 0, .2);
  }
  .theme--dark table.statistic-table>tbody>tr.others {
    background: rgba(0, 0, 0, .2);
  }
  .theme--dark table.statistic-table>tbody>tr.total {
    background: rgba(0, 0, 0, .2);
  }
  .theme--dark .row-problem-total table>tbody>tr:nth-last-of-type(1){
    background: rgba(255, 0, 0, .2);
  }
  .theme--dark table.freeze-unset-bg.freeze-1>thead>tr>th:nth-of-type(1) {
    background-color: #424242 !important;
  }
  .theme--dark table.freeze-unset-bg.freeze-2>thead>tr>th:nth-of-type(2) {
    background-color: #424242 !important;
  }
  .theme--dark table.freeze-unset-bg.freeze-3>thead>tr>th:nth-of-type(3) {
    background-color: #424242 !important;
  }
  .theme--dark table.freeze-unset-bg.freeze-4>thead>tr>th:nth-of-type(4) {
    background-color: #424242 !important;
  }
  .theme--dark table.freeze-unset-bg.freeze-1>tbody>tr>td:nth-of-type(1) {
    background-color: #424242;
  }
  .theme--dark table.freeze-unset-bg.freeze-1>tbody>tr:nth-of-type(odd)>td:nth-of-type(1) {
    background-color: #333333;
  }
  .theme--dark table.freeze-unset-bg.freeze-2>tbody>tr>td:nth-of-type(2) {
    background-color: #424242;
  }
  .theme--dark table.freeze-unset-bg.freeze-2>tbody>tr:nth-of-type(odd)>td:nth-of-type(2) {
    background-color: #333333;
  }
  .theme--dark table.freeze-unset-bg.freeze-3>tbody>tr>td:nth-of-type(3) {
    background-color: #424242;
  }
  .theme--dark table.freeze-unset-bg.freeze-3>tbody>tr:nth-of-type(odd)>td:nth-of-type(3) {
    background-color: #333333;
  }
  .theme--dark table.freeze-unset-bg.freeze-4>tbody>tr>td:nth-of-type(4) {
    background-color: #424242;
  }
  .theme--dark table.freeze-unset-bg.freeze-4>tbody>tr:nth-of-type(odd)>td:nth-of-type(4) {
    background-color: #333333;
  }
  .theme--dark table.freeze-unset-bg.freeze-border-1>tbody>tr>td:nth-of-type(1),
  .theme--dark table.freeze-unset-bg.freeze-border-1>thead>tr>th:nth-of-type(1) {
    background-image: linear-gradient(to right, transparent 50%, #ffffff1c 95%, #222 95%, transparent 100%);
  }
  .theme--dark table.freeze-unset-bg.freeze-border-2>tbody>tr>td:nth-of-type(2),
  .theme--dark table.freeze-unset-bg.freeze-border-2>thead>tr>th:nth-of-type(2) {
    background-image: linear-gradient(to right, transparent 50%, #ffffff1c 95%, #222 95%, transparent 100%);
  }
  .theme--dark table.freeze-unset-bg.freeze-border-3>tbody>tr>td:nth-of-type(3),
  .theme--dark table.freeze-unset-bg.freeze-border-3>thead>tr>th:nth-of-type(3) {
    background-image: linear-gradient(to right, transparent 50%, #ffffff1c 95%, #222 95%, transparent 100%);
  }
  .theme--dark table.freeze-unset-bg.freeze-border-4>tbody>tr>td:nth-of-type(4),
  .theme--dark table.freeze-unset-bg.freeze-border-4>thead>tr>th:nth-of-type(4) {
    background-image: linear-gradient(to right, transparent 50%, #ffffff1c 95%, #222 95%, transparent 100%);
  }
  .theme--dark table.statistic-table>tbody>tr>td {
    height: auto;
    padding: 0 5px;
    word-break: break-word;
    white-space: normal;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 13px;
  }
  .theme--dark table.statistic-table>tbody>tr>td.problem-module {
    /*background: rgba(0, 0, 0, .2);*/
    background: #292929;
  }
  .theme--dark table.statistic-table>tbody>tr>td.table-module-warning {
    /*background-color: rgba(255, 0, 0, .1);*/
    background-color: #553333!important;
  }
  .theme--dark table.statistic-table:not(.icon-white)>tbody>tr>td i{
    color: #ddaa00;
  }
  .theme--dark table.statistic-table>tbody>tr>td.table-text-zero {
    color: #666666;
  }
  .cfr .long-title-bar{
    width: calc(200% + 40px)!important;
    z-index: 1!important;
  }
  .cfr table.analyzer-table>tbody>tr>td>div>.strong{
    font-size: 25px!important;
    font-weight: 700!important;
  }
  .cfr table.analyzer-table>tbody>tr>td>.v-chip{
    margin: 0!important;
    margin-top: 10px!important;
    background-color: transparent;
  }
  .cfr table.analyzer-table>tbody>tr>td>.v-chip>.v-chip__content{
    font-size: 18px!important;
    padding: 10px 0!important;
  }
  .cfr table.analyzer-table>tbody>tr>td>div>span{
    word-break: keep-all;
    font-weight: 900!important;
  }

  .cfr .merge-table>.v-table__overflow>.v-datatable>thead>tr>th.rowspan-2 {
    height: 70px;
  }
  .cfr .merge-table>.v-table__overflow>.v-datatable>thead>tr>th.rowspan-children {
    height: 70px;
    padding-bottom: 10px!important;
    vertical-align: bottom;
    position: relative;
  }
  .theme--light .cfr .merge-table>.v-table__overflow>.v-datatable>thead>tr>th.rowspan-children::before {
    content: '';
    padding: 10px;
    border-bottom: 1px solid hsla(0,0%,0%,.12);
    position: absolute;
    left: 0;
    bottom: 35px;
    width: 100%;
  }
  .theme--light .cfr .merge-table>.v-table__overflow>.v-datatable>thead>tr>th.colspan-title-ssd::before {
    content: '有SSD';
    padding: 0 10px;
    user-select: none;
    color: #BA2200;
    font-weight: bold;
    word-break: break-word;
    white-space: normal;
    text-align: left;
    font-size: 16px;
  }
  .theme--light .cfr .merge-table>.v-table__overflow>.v-datatable>thead>tr>th.colspan-title-hd::before {
    content: '無SSD';
    padding: 0 10px;
    user-select: none;
    color: #BA2200;
    font-weight: bold;
    word-break: break-word;
    white-space: normal;
    text-align: left;
    font-size: 16px;
  }
  .theme--dark .cfr .merge-table>.v-table__overflow>.v-datatable>thead>tr>th.rowspan-children::before {
    content: '';
    padding: 10px;
    border-bottom: 1px solid hsla(0,0%,100%,.12);
    position: absolute;
    left: 0;
    bottom: 35px;
    width: 100%;
  }
  .theme--dark .cfr .merge-table>.v-table__overflow>.v-datatable>thead>tr>th.colspan-title-ssd::before {
    content: '有SSD';
    padding: 0 10px;
    user-select: none;
    color: #ff9800;
    font-weight: bold;
    word-break: break-word;
    white-space: normal;
    text-align: left;
    font-size: 16px;
  }

  .theme--dark .cfr .merge-table>.v-table__overflow>.v-datatable>thead>tr>th.colspan-title-hd::before {
    content: '無SSD';
    padding: 0 10px;
    user-select: none;
    color: #ff9800;
    font-weight: bold;
    word-break: break-word;
    white-space: normal;
    text-align: left;
    font-size: 16px;
  }
  .system .v-text-field.danger-input > .v-input__control > .v-input__slot:after{
    border-color: #ffffff;
  }
  .system .opacity-50 > .v-card__text > div:not(:nth-last-of-type(1)){
    opacity: 0.25;
    transition: 0.25s;
  }
  .theme--light .system table{
    background: #ffffff!important;
  }
  .theme--light .system table>tbody>tr:nth-of-type(odd) {
    background: rgba(0, 0, 0, .1)!important;
  }
  .theme--light .system table>tbody>tr:nth-of-type(even) {
    background: #ffffff!important;
  }
  .theme--light .system table>tbody>tr.selected {
    background: #eff6f6!important;
  }
  .theme--light .system table td{
    color: #000000!important;
  }
  .theme--dark .system table>tbody>tr.selected {
    background: rgba(255, 0, 0, .2);
  }
  .system table>tbody>tr>td.progress{
    background: transparent!important;
  }
  .system table>tbody>tr>td.progress>.progress-bar{
    overflow: visible!important;
  }
  table.v-table {
    width: auto;
    max-width: none;
    -webkit-box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)!important;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)!important;
  }

  table.v-table tbody tr {
    transition: 0.25s;
  }

  table.v-table tbody tr:not(:first-child) {
    border-top: 1px solid hsla(0,0%,100%,.12)!important;
  }

  table.v-table tbody tr.check-row {
    background: #f44336!important;
  }

  table.v-table tbody tr.check-row:nth-of-type(odd):hover,table.v-table tbody tr.check-row:nth-of-type(even):hover {
    background: #f44336!important;
  }

  table.v-table thead th {
    text-align: center!important;
    padding: 0 10px!important;
  }

  .table-align-auto table.v-table thead th {
    text-align: unset!important;
  }

  .table-align-left table.v-table thead th {
    text-align: left!important;
  }

  .table-align-right table.v-table thead th {
    text-align: right!important;
  }

  .theme--light.v-table td {
    color: rgba(0,0,0,1)!important;
  }

  .theme--dark table.v-table thead th>span {
    color: #ff9800!important;
    font-weight: bold!important;
    word-break: break-word;
    white-space: normal;
  }

  .theme--light table.v-table thead th>span {
    color: #BA2200!important;
    font-weight: bold!important;
    word-break: break-word;
    white-space: normal;
  }

  table.v-table tbody td, table.v-table thead tr {
    height: auto;
    padding: 0 10px!important;
    word-break: break-word;
    white-space: normal;
    box-sizing: border-box;
  }

  .theme--dark table.v-table tbody td {
    font-weight: 400!important;
    font-size: 13px!important;
  }

  .theme--light table.v-table tbody td {
    font-weight: 600!important;
    font-size: 13px!important;
  }

  /* .theme--dark .v-pagination__navigation {
    background: #424242;
  }
  .theme--dark .v-pagination__navigation .v-icon {
    color: #fff;
  }
  .theme--light .v-pagination__navigation {
    background: #fff;
  }
  .theme--light .v-pagination__navigation .v-icon {
    color: rgba(0,0,0,.54);
  } */

  .group-edit-area {
    float: right;
    width: calc(100% - 310px);
    height: calc(100vh - 230px);
  }

  .freeze-title-small {
    float: left;
    vertical-align: top;
    height: calc(100vh - 230px)!important;
    width: 300px;
    margin-right: 10px;
  }

  .freeze-title-small table.v-table {
    display: block!important;
    /* overflow-x: hidden; */
    overflow-x: hidden;
    /* padding-right: 10px; */
    text-align: left;
  }

  .freeze-title-small table.v-table tbody {
    max-height: calc(100vh - 290px)!important;
    display: inline-block!important;
    overflow-y: auto!important;
    overflow-x: hidden!important;
    padding-bottom: 10px;
    min-height: 440px;
  }

  /*
  .freeze-title .v-table__overflow {
    width: auto!important;
    overflow-x: auto!important;
    overflow-y: hidden!important;
  } */

  .freeze-title table.v-table {
    display: block!important;
    overflow-x: auto;
    /* padding-right: 10px; */
    /* display: flex!important; */
    /* flex-flow: column!important; */
  }

  .freeze-title.showList table.v-table {
    overflow-x: auto!important;
  }

  table.freeze-title.showList {
    overflow-x: auto!important;
    display: block!important;
  }
  /* .freeze-title table.v-table thead {
    width: 100%!important;
  } */

  .freeze-title table.v-table thead th {
    text-align: left!important;
  }
  table.freeze-title.showList thead th {
    vertical-align: top;
    min-width: 125px;
  }

  .freeze-title table.v-table tbody {
    max-height: calc(100vh - 365px)!important;
    display: inline-block!important;
    overflow-y: auto!important;
    overflow-x: hidden!important;
    /* padding-bottom: 10px; */
  }

  .freeze-title.freeze-col table.v-table tbody {
    max-height: calc(100vh - 365px)!important;
    display: table-row-group!important;
    overflow-y: auto!important;
    overflow-x: hidden!important;
    /* padding-bottom: 10px; */
  }

  table.freeze-title.showList tbody {
    max-height: calc(100vh - 365px)!important;
    display: inline-block!important;
    overflow-y: auto!important;
    overflow-x: hidden!important;
    /* padding-bottom: 10px; */
  }

  table.freeze-title.showList tbody td {
    min-width: 125px;
    padding: 0 10px!important;
    word-break: break-word!important;
    white-space: normal;
    box-sizing: border-box;
  }

  .height-row-1 table.v-table tr{
    height: 24px!important;
  }

  .height-row-2 table.v-table tr{
    height: 30px!important;
  }

  .height-row-3 table.v-table tr{
    height: 36px!important;
  }

  .theme--dark .freeze-1 tr th:nth-child(1), .theme--dark .freeze-1 tr td:nth-child(1){
    left: 0px;
    position: sticky!important;
    top: 0;
    background: #433;
    z-index: 1;
  }

  .theme--light .freeze-1 tr th:nth-child(1), .theme--light .freeze-1 tr td:nth-child(1){
    left: 0px;
    position: sticky!important;
    top: 0;
    background: #eff6f6;
    z-index: 1;
  }

  .freeze-1.freeze-unset-bg tr th:nth-child(1), .freeze-1.freeze-unset-bg tr td:nth-child(1){
    background: unset;
  }

  .theme--dark .freeze-2 tr th:nth-child(2), .theme--dark .freeze-2 tr td:nth-child(2){
    left: 50px;
    position: sticky!important;
    top: 0;
    background: #433;
    z-index: 1;
  }

  .theme--light .freeze-2 tr th:nth-child(2), .theme--light .freeze-2 tr td:nth-child(2){
    left: 50px;
    position: sticky!important;
    top: 0;
    background: #eff6f6;
    z-index: 1;
  }

  .freeze-2.freeze-unset-bg tr th:nth-child(2), .freeze-2.freeze-unset-bg tr td:nth-child(2){
    background: unset;
  }

  .theme--dark .freeze-3 tr th:nth-child(3), .theme--dark .freeze-3 tr td:nth-child(3){
    left: 100px;
    position: sticky!important;
    top: 0;
    background: #433;
    z-index: 1;
  }

  .theme--light .freeze-3 tr th:nth-child(3), .theme--light .freeze-3 tr td:nth-child(3){
    left: 100px;
    position: sticky!important;
    top: 0;
    background: #eff6f6;
    z-index: 1;
  }

  .freeze-3.freeze-unset-bg tr th:nth-child(3), .freeze-3.freeze-unset-bg tr td:nth-child(3){
    background: unset;
  }

  .theme--dark .freeze-4 tr th:nth-child(4), .theme--dark .freeze-4 tr td:nth-child(4){
    left: 150px;
    position: sticky!important;
    top: 0;
    background: #433;
    z-index: 1;
  }

  .theme--light .freeze-4 tr th:nth-child(4), .theme--light .freeze-4 tr td:nth-child(4){
    left: 150px;
    position: sticky!important;
    top: 0;
    background: #eff6f6;
    z-index: 1;
  }

  .freeze-4.freeze-unset-bg tr th:nth-child(4), .freeze-4.freeze-unset-bg tr td:nth-child(4){
    background: unset;
  }

  .v-chip {
    margin: 0 4px!important;
    font-size: 0.5rem!important;
  }

  table.v-table tbody td button.small, table.v-table thead th button.small {
    height: auto;
    padding: 2px 16px!important;
    font-size: 0.5rem!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
  }

  table.v-table tbody td button.small i, table.v-table thead th button.small i {
    font-size: 0.5rem!important;
  }

  .error.alerts_dark {
    color:#FFF;
    background-color:#424242!important;
    box-shadow:0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)!important;
  }

  .error.alerts_light {
    color:#000;
    background-color:#FFFFFF!important;
    box-shadow:0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)!important;
  }

  .v-card__title h6 {
    margin-bottom: 0px!important;
  }

  .d-table-t0 .v-card__text{
    padding-top:0!important;
  }
  .d-table-full table.v-table {
    width: 100%!important;
  }
  .no-data-left table.v-table tbody tr:first-child td:first-child {
    text-align: left!important;
  }

  .td-check {
    height: 20px!important;
  }

  .td-check .v-input {
    transform: scale(0.8);
  }

  .td-check .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
    margin-bottom: 0!important;
  }

  .td-check .v-input--selection-controls__input {
    height: 20px!important;
  }

  .td-check .v-input--selection-controls__ripple {
    margin: 0!important;
    height: auto!important;
  }

  .td-check .v-messages {
    display: none!important;
  }

  .td-check .v-input--is-focused .v-icon {
    color: #fff!important;
  }

  .v-card--material-stats > hr.v-divider, .v-card--material-stats > .v-card__actions {
    display: none;
  }

  .v-offset-none .v-offset {
    display: none;
  }

  .v-offset-shadow .v-offset>div {
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12)!important;
  }

  .v-card__padding .v-card__text {
    padding-top: 10px!important;
    padding-bottom: 10px!important;
  }

  .theme--dark .c3-chart {
    background: #212121;
    padding: 1rem;
    margin-top: -5rem;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    border-radius: 6px;
    box-shadow: 0 12px 20px -10px rgba(33, 33, 33, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(33, 33, 33, 0.2) !important;
  }
  .theme--dark .c3-axis {
    stroke: #FFF!important;
    font-family: auto!important;
    font-size: 0.8rem!important;
  }
  .c3-grid line {
    stroke: #666;
  }
  .theme--dark .c3-axis .domain {
    stroke: #fff!important;
    stroke-width: 1.5px!important;
  }
  .theme--dark .c3-legend-item {
    fill: #fff!important;
    font-size: 0.8rem!important;
    font-weight: normal!important;
  }

  .theme--light .c3-chart {
    background: #eee;
    padding: 1rem;
    margin-top: -5rem;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    border-radius: 6px;
    box-shadow: 0 12px 20px -10px rgba(33, 33, 33, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(33, 33, 33, 0.2) !important;
  }
  .theme--light .c3-axis {
    stroke: #666!important;
    font-family: auto!important;
    font-size: 0.8rem!important;

  }
  .theme--light .c3-axis .domain {
    stroke: #000!important;
    stroke-width: 1.5px!important;
  }
  .theme--light .c3-legend-item {
    fill: #000!important;
    font-size: 0.8rem!important;
    font-weight: normal!important;
  }
  .v-c3-chart{
    margin-top: 6rem;
  }
  .v-c3-chart>.v-card__text>.chart-btn {
    width: calc( 100% - 200px - 16px);
  }

  .c3-chart-texts {
    font-size: 1.25rem;
  }
  .v-c3-chart>.v-card__text>div+div .v-alert--notification{
    /* width: 200px; */
    min-width: 200px;
    margin-left: 16px;
  }
  .c3-tooltip-container th{
    background: #666!important;
    /* color: #666!important; */
  }
  .c3-tooltip-container td{
    color: #000!important;
  }
  .c3-tip-weeks .c3-tooltip-container td.value{
    text-align: left!important;
  }
  .c3-line {
    stroke-width: 5px!important;
  }
  .cfr .c3-line {
    stroke-width: 2px!important;
  }
  .c3-circle.c3-shape {
    /* r: 10!important; */
  }
  .c3-grid line {
    stroke: #666;
  }
  .c3-chart-arc text {
    font-size: 1.5rem!important;
  }
  .c3-chart:not(.c3-click) > div.vuec3-chart.c3 > svg > g:nth-child(2) > g.c3-chart > g.c3-event-rects > rect.c3-event-rect {
    cursor: default!important;
  }
  .c3-click .c3-shape {
    /* cursor: pointer!important; */
  }
  /* .v-btn {
    min-width: auto!important;
    height: 30px;
  } */
  .theme--light .cfr .c3-text{
    fill: #000000!important;
    stroke: #000000!important;
    text-shadow: 0px 0px 10px #ffffff!important;
  }
  .theme--dark .cfr .c3-text{
    fill: #ffffff!important;
    stroke: #ffffff!important;
    text-shadow: 0px 0px 10px #000000!important;
  }
  .theme--dark .cfr .c3-circle {
    /*stroke-width: 10px;*/
    /*stroke: #fff;*/
  }
  .emoji .title{
    font-family: "Apple Color Emoji","Segoe UI Emoji"!important;
  }
  .v-btn {
    min-width: auto!important;
    padding: 0 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 30px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 14px;
    font-weight: 500;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 6px 8px;
    min-width: 88px;
    outline: 0;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transition: .3s cubic-bezier(.25,.8,.5,1),color 1ms;
    transition: .3s cubic-bezier(.25,.8,.5,1),color 1ms;
    position: relative;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  small, .small {
    font-size: 100%!important;
  }

  .v-flex .v-card__text {
    display: flex;
  }

  .v-date-picker-table .v-btn.v-btn--floating {
    padding: 0!important;
    border-radius: 50%!important;
    height: 32px!important;
    width: 32px!important;
    line-height: 1.5em!important;
  }

  .v-date-picker-table .v-btn--active:before, .v-date-picker-table  .v-btn:focus:before, .v-date-picker-table  .v-btn:hover:before {
    background-color: currentColor!important;
  }

  .v-alert--outline.text--error {
    border: 0px RGBA(255,255,255,0.5) solid!important;
    color: #fff !important;
    width: calc(100% - 2rem);
    background: #f44336;
    text-align: center;
  }

  .theme--dark .mdi-checkbox-blank-outline:before {
    -webkit-text-stroke: 1px #ffffff!important;
  }
  .theme--light .mdi-checkbox-blank-outline:before {
    -webkit-text-stroke: 1px #000000!important;
  }

  .v-card--material-stats p.category {
    color: RGBA(170,170,170,1)!important;
  }

  .v-card--material-stats.title-color p.category {
    color: RGBA(255,255,255,0.75)!important;
  }

  .v-card--material-stats.title-color h3 {
    color: RGBA(255,255,255,1)!important;
  }

  .v-card--material-stats.scale h3 {
    transform-origin: right;
    /* transform: scaleX(0.9); */
    float: right;
  }
  .scale-1 h3 {
    transform: scaleX(0.1);
  }
  .scale-2 h3 {
    transform: scaleX(0.2);
  }
  .scale-3 h3 {
    transform: scaleX(0.3);
  }
  .scale-4 h3 {
    transform: scaleX(0.4);
  }
  .scale-5 h3 {
    transform: scaleX(0.52);
  }
  .scale-6 h3 {
    transform: scaleX(0.6);
  }
  .scale-7 h3 {
    transform: scaleX(0.7);
  }
  .scale-8 h3 {
    transform: scaleX(0.8);
  }
  .scale-9 h3 {
    transform: scaleX(0.9);
  }
  .theme--dark .list-item-area * {
    color: #ffffff;
  }
  .theme--light .list-item-area * {
    color: #000000;
  }
  .list-item-area {
    transition: 0.1s;
    padding-left: 10px;
    position: relative;
  }

  .list-item-area>span:hover {
    background: rgba(255,255,255,0.25);
  }

  .list-item-area.list-item-click {
    background: rgba(255,100,100,0.5);
  }

  .list-item-area.list-item-click:hover {
    background: rgba(255,100,100,0.75);
  }

  .list-item-area > span {
    cursor: pointer;
    width: calc(100% - 85px);
    display: inline-block;
  }
  .list-item-area > span.group-title {
    /*color: #f44336!important;*/
    display: flex;
    align-items: center;
    text-shadow: 0.05em 0.05em 0.15em #00000055;
  }
  .list-item-area > span.group-title > i {
    font-size: 20px;
    /*color: #f44336!important;*/
  }
  .list-item-area > span:not(.group-title) > span {
    display: inline-block;
    max-width: 100%;
    text-align: justify;
    padding-right: 10px;
  }
  .list-item-area > span.group-title > span {
    word-break: keep-all!important;
    margin-right: 5px;
  }
  .list-item-area > span.group-title > hr {
    margin: 0!important;
    border: 0!important;
    border-top: 1px solid #999999!important;
    width: -webkit-fill-available!important;
    border-image: repeating-linear-gradient(left, #999 0%, #999 70%, transparent 100%) !important;
    -moz-border-image: -moz-repeating-linear-gradient(left, #999 0%, #999 70%, transparent 100%) !important;
    -webkit-border-image: -webkit-repeating-linear-gradient(left, #999 0%, #999 70%, transparent 100%) !important;
    border-image-slice: 1!important;
  }
  .list-item-area > span.group-all {
    margin-left: 20px;
    max-width: calc(100% - 20px);
  }
  .list-item-area > span.group-item {
    margin-left: 20px;
    max-width: calc(100% - 20px);
  }
  .list-item-area > span.group-item-title {
    margin-left: 40px;
    max-width: calc(100% - 40px);
  }
  .list-item-area.vertical-line::after {
    content: " ";
    /*border-left: 1px solid #666!important;*/
    height: calc(100% - 25px);
    width: 9px;
    top: 15px;
    left: 39px;
    position: absolute;
    border-style: solid;
    border-width: 0 0 1px 1px;
    border-radius: 0 0 0 5px;
    border-color:  #666!important;
    /*
    border-image: linear-gradient(left, #666 0%, transparent 70%);
    -moz-border-image: -moz-linear-gradient(left, #666 0%, transparent 70%);
    -webkit-border-image: -webkit-linear-gradient(left, #666 0%, transparent 70%);
    border-image-slice: 1;
    */
  }
  .list-item-area.list-item-menu-selector.vertical-line::after {
    left: 29px!important;
  }
  .list-item-area.list-item-menu-selector {
    margin-left: 20px;
    padding: 0;
    position: relative;
    margin-right: 0;
  }
  .list-item-area.list-item-menu-selector > span.group-title {
    position: relative;
    display: flex;
    align-items: center;
    text-shadow: 0.05em 0.05em 0.15em #00000055;
    /*width: 21px!important;*/
    /*position: absolute;*/
    /*top: -21px;*/
    /*margin-bottom: -21px;*/
    z-index: 9;
  }
  .list-item-area.list-item-menu-selector > span.group-title > hr {
    margin: 0!important;
    border: 0!important;
    border-top: 1px solid #666!important;
    width: -webkit-fill-available!important;
    border-image: repeating-linear-gradient(left, #666 0%, #999 70%, transparent 100%) !important;
    -moz-border-image: -moz-repeating-linear-gradient(left, #666 0%, #999 70%, transparent 100%) !important;
    -webkit-border-image: -webkit-repeating-linear-gradient(left, #666 0%, #999 70%, transparent 100%) !important;
    border-image-slice: 1!important;
  }
  .list-item-area.list-item-menu-selector.list-item-alone-menu-selector > span.group-title {
    width: 21px!important;
    position: absolute;
    top: -21px;
    margin-bottom: -21px;
  }
  .list-item-area.list-item-menu-selector.list-item-alone-menu-selector > span.group-title > hr {
    display: none;
  }
  .list-item-btn {
    line-height: 0;
    font-size: 0.75rem;
    padding: 5px 10px;
    margin: 0;
    height: 1rem;
    margin-left: 0.75rem;
    margin-top: -4px;
  }
  .rotate90{
    -moz-transform:rotate(90deg);
    -webkit-transform:rotate(90deg);
    -o-transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    transform:rotate(90deg);
  }
  .calendar {
    width: 100%;
    overflow-x: auto;
    margin-top: -16px;
  }

  .calendar>div {
    min-width: 1580px;
  }

  .calendar-weeks {
    width: 70px;
    display: inline-block;
    padding-top: 55px;
    float: left;
  }

  .calendar-date>div {
    min-width: 1360px;
  }

  .calendar-week {
    width: calc((100% - 105px + 70px) / 7);
    height: 30px;
    display: inline-block;
    text-align: center;
    margin: 2px;
    padding: 0px;
    float: left;
  }

  .calendar-week.calendar-week-np {
    width: calc((100% - 105px) / 8);
  }

  .theme--dark .calendar-week {
    background: RGBA(255,255,255,0);
  }

  .theme--light .calendar-week {
    background: RGBA(255,255,255,0);
  }

  .calendar-week>span {
    font-weight: bold!important;
    font-size: 1.25rem;
  }

  .calendar-np {
    width: calc((((100% - 115px + 560px) / 8 ) / 2) + 75px);
    height: 30px;
    display: inline-block;
    text-align: center;
    margin: 2px;
    padding: 5px;
    float: left;
  }

  .calendar-week-np + .calendar-np {
    width: calc((((100% - 115px + 560px) / 8 ) / 2) - 75px);
  }

  .calendar-weeks {
    width: 70px;
    /* height: 100px; */
    display: inline-block;
    text-align: center;
    margin: 0px;
    padding: 5px;
    transition: 0.25s;
  }

  .calendar-row {
    display: flex;
    /* max-height: 128px; */
    transition: 0.25s;
    overflow-y: hidden;
    width: 100%;
  }

  .calendar-row-unfold {
    /* max-height: 100vh; */
  }

  .calendar-row-np {
    display: block;
    max-height: 100%;
    /* overflow-y: hidden; */
  }

  .calendar-row-np>div {
    display: flex;
  }

  .calendar-day {
    height: auto;
    min-height: 80px;
    /* max-height: 128px; */
    width: calc((100% - 105px) / 7);
    display: inline-block;
    text-align: center;
    margin: 2px;
    padding: 0px;
    float: left;
    position: relative;
    transition: 0.25s;
    font-weight: bold;
    font-size: 3rem;
  }

  .calendar-row-unfold .calendar-day{
    height: auto;
    /* max-height: 100vh; */
  }

  .theme--dark .calendar-day {
    background: RGBA(255,255,255,0.05);
    color: RGBA(255,255,255,0.1);
  }

  .theme--light .calendar-day {
    background: RGBA(0,0,0,0.02);
    color: RGBA(0,0,0,0.05);
  }

  .calendar-day-fail {
    min-width: 100px;
    text-align: left;
    position: relative;
    transition: 0.5s;
    font-size: 1rem;
    padding-right: 50px;
  }

  .calendar-day-fail:not(.calendar-day-np)>.calendar-fail:nth-of-type(5) {
    /* margin-bottom: 0px; */
  }

  .calendar-row-unfold .calendar-day-fail:not(.calendar-day-np)>.calendar-fail:nth-of-type(5) {
    /* margin-bottom: -5px; */
  }

  .calendar-day-fail:not(.calendar-day-np)>.calendar-fail:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)):not(:nth-of-type(4)):not(:nth-of-type(5)) {
    max-height: 0;
    transition: 0.25s;
    overflow: hidden;
  }

  .calendar-row-unfold .calendar-day-fail:not(.calendar-day-np)>.calendar-fail:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)):not(:nth-of-type(4)):not(:nth-of-type(5)) {
    max-height: 30px;
  }

  .calendar-day-fail:not(.calendar-day-np)>.calendar-fail-group:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)) {
    max-height: 0;
    transition: 0.25s;
    overflow: hidden;
  }

  .calendar-row-unfold .calendar-day-fail:not(.calendar-day-np)>.calendar-fail-group:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)) {
    max-height: 40px;
  }

  .calendar-day.calendar-day-np {
    min-height: 50px;
    max-height: 50px;
    width: calc((100% - 105px) / 8 );
  }

  .calendar-day.calendar-day-np-np {
    min-height: 0px;
    max-height: 50px;
    width: calc((((100% - 105px + 560px) / 8 ) / 2) + 75px);
    font-size: 1rem;
    text-align: left;
  }

  .calendar-day-np + .calendar-day.calendar-day-np-np {
    width: calc((((100% - 105px + 560px) / 8 ) / 2) - 75px);
    text-align: right;
  }

  .calendar-day-np:not(.calendar-day-fail) > .calendar-fail > div{
    display: none;
  }
  .calendar-row-np:nth-child(2) > div > .calendar-day-np > a {
    cursor: default;
    margin-top: -.9rem;
  }

  .calendar-row-np:not(:nth-child(2)) > div > .calendar-day-np > a {
    display:none;
  }

  .calendar-fail-bottom {
    margin-top: -10px;
  }

  .calendar-day-icon {
    transform:rotate(-180deg);
    position: absolute;
    cursor: pointer;
    padding: 5px;
    right: 5px;
    bottom: 7px;
  }

  .theme--dark .calendar-day-icon:hover {
    background: RGBA(0,0,0,0.5);
    border-radius: 100%;
  }

  .theme--light .calendar-day-icon:hover {
    background: RGBA(255,255,255,0.75);
    border-radius: 100%;
  }

  .calendar-row-unfold .calendar-day-icon {
    transform:rotate(0deg);
  }

  .theme--dark .calendar-day-fail {
    background: RGBA(33,33,33,1);
    color: RGBA(180,180,180,1);
  }

  .theme--light .calendar-day-fail {
    background: RGBA(252,252,252,1);
    color: RGBA(225,225,225,1);
  }

  .theme--dark .calendar-day-fail:hover {
    /* background: RGBA(33,33,33,1); */
  }

  .theme--light .calendar-day-fail:hover {
    /* background: RGBA(252,252,252,1); */
  }

  .calendar-day:not(.calendar-day-np-np)>a {
    transition: 0.5s;
    font-size: 3rem;
    font-weight: bold!important;
    position: absolute;
    right: 5px;
    color: inherit!important;
  }

  .theme--dark .calendar-day-fail:not(.calendar-day-np)>a:hover {
    cursor: pointer;
    /* color: RGBA(255, 255, 255,.75); */
  }

  .theme--light .calendar-day-fail:not(.calendar-day-np)>a:hover {
    cursor: pointer;
    /* color: RGBA(0, 0, 0,.5); */
  }

  .calendar-fail, .calendar-fail-group {
    cursor: pointer;
    display: block;
    font-size: 1rem;
    margin: 0 0.25rem;
    position: relative;
    font-weight: normal;
    /* margin-bottom: -5px; */
  }

  .calendar-fail>a, .calendar-fail>a>span, .calendar-fail-group>a {
    font-weight: bold!important;
  }

  .calendar-fail:hover>a, .calendar-fail:hover>a>span, .calendar-fail-group:hover>a {
    /* font-weight: bold!important; */
    font-size: 1.05rem!important;
  }

  /*.calendar-fail.cursor *{
    cursor: pointer;
  }*/

  .theme--dark .calendar-around {
    background: RGBA(75,75,75,1);
    color: RGBA(55,55,55,1);
  }

  .theme--light .calendar-around {
    background: RGBA(217,217,217,1);
    color: RGBA(180,180,180,1);
  }

  .theme--dark .calendar-around:hover {
    /* background: RGBA(75,75,75,1); */
  }

  .theme--light .calendar-around:hover {
    /* background: RGBA(217,217,217,1); */
  }

  .bookmark-area .bookmark-list{
    max-height: 35px;
    overflow: hidden;
    transition: 0.25s;
  }
  .bookmark-area:hover .bookmark-list{
    max-height: 100vh;
  }
  .theme--light .bookmark>div{
    display: flex;
    align-items: center;
    position: relative;
    border: #999999 2px solid;
    border-radius: 25px;
    padding: 0px 5px !important;
    padding-left: 25px !important;
    margin: 5px !important;
    min-height: 25px;
    /*color: #aaaaaa;*/
    cursor: pointer;
    color: #999999;
    font-size: 10px;
  }
  .theme--light .bookmark:hover>div{
    border: #ff5722 2px solid;
    color: #000000;
  }
  .theme--light .bookmark>div>span{
    font-weight: 700!important;
  }
  .theme--light .bookmark>div>i:nth-of-type(1){
    color: #666666!important;
    padding: 5px;
    font-size: 20px;
    border-radius: 50%;
    margin-right: 5px!important;
    position: absolute;
    top: -7px;
    left: -2px;
  }
  .theme--light .bookmark>div>i:nth-of-type(2){
    opacity: 0;
    color: #ffffff!important;
    background: #ff5722!important;
    padding: 5px;
    font-size: 15px;
    border-radius: 50%;
    margin-right: 5px!important;
    position: absolute;
    top: -2px;
    left: -2px;
  }
  .theme--light .bookmark:hover>div>i:nth-of-type(2){
    opacity: 1;
  }
  .theme--light .bookmark>div>i:nth-of-type(2):hover{
    color: #ffffff!important;
    background: #000000!important;
  }
  .theme--light .bookmark>div>i:nth-of-type(3){
    font-size: 15px;
    color: #000000!important;
    margin-left: 5px;
  }
  .theme--light .bookmark>div>i:nth-of-type(3):hover{
    color: #ff5722!important;
    font-size: 20px;
    margin-left: 0px;
  }
  .theme--dark .bookmark>div{
    display: flex;
    align-items: center;
    position: relative;
    border: #999999 2px solid;
    border-radius: 25px;
    padding: 0px 5px !important;
    padding-left: 25px !important;
    margin: 5px !important;
    min-height: 25px;
    /*color: #aaaaaa;*/
    cursor: pointer;
    color: #999999;
    font-size: 10px;
  }
  .theme--dark .bookmark:hover>div{
    border: #ff5722 2px solid;
    color: #ffffff;
  }
  .theme--dark .bookmark>div>span{
    font-weight: 700!important;
  }
  .theme--dark .bookmark>div>i:nth-of-type(1){
    color: #ffffff!important;
    padding: 5px;
    font-size: 20px;
    border-radius: 50%;
    margin-right: 5px!important;
    position: absolute;
    top: -7px;
    left: -2px;
  }
  .theme--dark .bookmark>div>i:nth-of-type(2){
    opacity: 0;
    color: #ffffff!important;
    background: #ff5722!important;
    padding: 5px;
    font-size: 15px;
    border-radius: 50%;
    margin-right: 5px!important;
    position: absolute;
    top: -2px;
    left: -2px;
  }
  .theme--dark .bookmark:hover>div>i:nth-of-type(2){
    opacity: 1;
  }
  .theme--dark .bookmark>div>i:nth-of-type(2):hover{
    color: #ffffff!important;
    background: #000000!important;
  }
  .theme--dark .bookmark>div>i:nth-of-type(3){
    font-size: 15px;
    color: #000000!important;
    margin-left: 5px;
  }
  .theme--dark .bookmark>div>i:nth-of-type(3):hover{
    color: #ff5722!important;
    font-size: 20px;
    margin-left: 0px;
  }

  .line-type{
    border: 1px solid;
    margin: 0!important;
    display: inline-flex;
    justify-content: center;
    color: #aaaaaa!important;
    border-color: #aaaaaa!important;
    background-color: transparent!important;
  }
  .line-type:nth-of-type(1){
    border-radius: 10px 0 0 10px;
  }
  .line-type:nth-of-type(2){
    border-radius: 0;
  }
  .line-type:nth-of-type(3){
    border-radius: 0 10px 10px 0;
  }
  .line-type.select{
    color: #ffffff!important;
    border-color: #f44336!important;
    background-color: #f44336!important;
    font-weight: 700!important;
  }
  .line-type.line.select{
    color: #ffffff!important;
    border-color: #ab47bc!important;
    background-color: #ab47bc!important;
  }
  .line-type.bar.select{
    color: #ffffff!important;
    border-color: #009688!important;
    background-color: #009688!important;
  }
  .line-type.pie.select{
    color: #ffffff!important;
    border-color: #1565c0!important;
    background-color: #1565c0!important;
  }
  .theme--light .list-item-type{
    display: flex;
    align-items: center;
    width: 100%;
  }
  .theme--light .list-item-type>span{
    color: orange!important;
    width: 5em;
    font-weight: 700!important;
  }
  .theme--light .list-item-type>hr{
    width: 100%;
    border-top: 1px solid orange;
    margin: 0!important;
  }
  .theme--light .list-item-group{
    width: calc(33.3% - 6px);
    border-radius: 10px;
    border: 1px solid;
    margin: 3px;
    display: inline-flex;
    justify-content: center;
    color: #666666!important;
    border-color: #666666!important;
    flex-flow: wrap;
  }
  .theme--light .list-item-group:hover{
    border-color: #000000!important;
    padding-bottom: 5px;
  }
  .theme--light .list-item-group>span{
    text-align: center;
    padding: 2px 5px;
    margin: 2px;
    color: #666666!important;
    border: #333333 1px solid!important;
    border-radius: 10px;
    display: none;
  }
  .theme--light .list-item-group.select-all,.theme--light .list-item-group.select-all>span,.theme--light .list-item-group>span.select{
    color: #ffffff!important;
    border-color: #f44336!important;
    background-color: #f44336!important;
    font-weight: 700!important;
  }
  .theme--light .list-item-group.select{
    color: #000000!important;
    border-color: #f44336!important;
    font-weight: 700!important;
  }
  .theme--light .list-item-group.select-all>span:not(:nth-of-type(1)){
    border: #ffffff 1px solid!important;
  }
  .theme--light .list-item-group>span:hover{
    color: #000000!important;
  }
  .theme--dark .list-item-type{
    display: flex;
    align-items: center;
    width: 100%;
  }
  .theme--dark .list-item-type>span{
    color: orange!important;
    width: 5em;
    font-weight: 700!important;
  }
  .theme--dark .list-item-type>hr{
    width: 100%;
    border-top: 1px solid orange;
    margin: 0!important;
  }
  .theme--dark .list-item-group{
    width: calc(33.3% - 6px);
    border-radius: 10px;
    border: 1px solid;
    margin: 3px;
    display: inline-flex;
    justify-content: center;
    color: #aaaaaa!important;
    border-color: #aaaaaa!important;
    flex-flow: wrap;
  }
  .theme--dark .list-item-group:hover{
    border-color: #ffffff!important;
    padding-bottom: 5px;
  }

  .theme--dark .list-item-group.select-all,.theme--dark .list-item-group.select-all>span,.theme--dark .list-item-group>span.select{
    color: #ffffff!important;
    border-color: #f44336!important;
    background-color: #f44336!important;
    font-weight: 700!important;
  }
  .theme--dark .list-item-group.select{
    color: #ffffff!important;
    border-color: #f44336!important;
    font-weight: 700!important;
  }
  .theme--dark .list-item-group.select-all>span:not(:nth-of-type(1)){
    border: #ffffff 1px solid!important;
  }
  .theme--dark .list-item-group>span{
    text-align: center;
    padding: 2px 5px;
    margin: 2px;
    color: #aaaaaa!important;
    border: #333333 1px solid!important;
    border-radius: 10px;
    display: none;
  }
  .theme--dark .list-item-group>span:hover{
    color: #ffffff!important;
  }
  .list-item-group>span:nth-of-type(1){
    display: block!important;
    padding: 3px;
    margin: 0;
    border: none!important;
  }
  .list-item-group:hover>span{
    display: block!important;
  }

  .theme--light .list-item{
    /*width: calc(50% - 20px);*/
    padding: 5px;
    border-radius: 10px;
    border: 1px solid;
    margin: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    color: #666666!important;
    border-color: #666666!important;
  }
  .theme--light .list-item.highlight{
    color: #000000!important;
  }
  .theme--light .list-item:hover{
    color: #000000!important;
    border-color: #000000!important;
  }
  .theme--light .list-item.select{
    color: #ffffff!important;
    border-color: #f44336!important;
    background-color: #f44336!important;
    font-weight: 700!important;
  }
  .theme--light .list-item.select > i{
    color: #ffffffaa!important;
  }
  .theme--light .list-item.translucent{
    opacity: 0.5;
  }
  .theme--dark .list-item{
    /*width: calc(50% - 20px);*/
    padding: 5px;
    border-radius: 10px;
    border: 1px solid;
    margin: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    color: #aaaaaa!important;
    border-color: #aaaaaa!important;
  }
  .theme--dark .list-item.highlight{
    color: #ffffff!important;
  }
  .theme--dark .list-item:hover{
    color: #ffffff!important;
    border-color: #ffffff!important;
  }
  .theme--dark .list-item.select{
    color: #ffffff!important;
    border-color: #f44336!important;
    background-color: #f44336!important;
    font-weight: 700!important;
  }
  .theme--dark .list-item.select > i{
    color: #ffffffaa!important;
  }
  .theme--dark .list-item.translucent{
    opacity: 0.5;
  }
  .line-item{
    position: relative;
    margin: 0!important;
  }
  .theme--light .line-item>div{
    border: 1px dashed;
    border-radius: 0 10px 10px 10px;
    padding: 0 !important;
    padding-left: 30px!important;
    padding-right: 30px!important;
    padding-bottom: 10px!important;
    margin-top: -10px;
    color: #333333;
    cursor: pointer;
    min-height: 45px;
  }
  .theme--light .line-item.active>div{
    border: #333333 2px solid!important;
    color: #000000;
  }
  .theme--light .line-item>div.read-only{
    color: #aa3333!important;
    cursor: default!important;
  }
  .theme--dark .line-item>div{
    border: 1px dashed;
    border-radius: 0 10px 10px 10px;
    padding: 0 !important;
    padding-left: 30px!important;
    padding-right: 30px!important;
    padding-bottom: 10px!important;
    margin-top: -10px;
    color: #aaaaaa;
    cursor: pointer;
    min-height: 45px;
  }
  .theme--dark .line-item.active>div{
    border: #ffffff 2px solid!important;
    color: #ffffff;
  }
  .theme--dark .line-item>div.read-only{
    color: #aa5555!important;
    cursor: default!important;
  }
  .line-item>div>div{
    display: inline-flex!important;
    margin: 0 10px!important;
    margin-top: 10px!important;
  }
  .line-item>div.read-only>div {
    cursor: pointer;
  }
  .line-item.active span{
    font-weight: 700!important;
  }
  .line-item span:hover{
    color: #f44336!important;
  }
  .line-item>h6{
    display: inline-flex;
    border-radius: 10px 10px 10px 0;
    color: #ffffff!important;
    padding: 0px !important;
    margin: 0;
    max-width: 100%;
    position: relative;
  }
  .line-item>h6.table-col{
    background-color: #3a7e3e!important;
  }
  .line-item>h6.line{
    background-color: #ab47bc!important;
  }
  .line-item>h6.bar{
    background-color: #009688!important;
  }
  .line-item>h6.pie{
    background-color: #1565c0!important;
  }
  .line-item>h6.base{
    background-color: #99cc55!important;
  }
  .line-item>h6>input{
    padding: 2px 10px !important;
    font-weight: 700!important;
  }
  .line-item>h6>i{
    font-size: 18px;
    position: absolute;
    top: -5px;
    left: -5px;
  }
  .line-item>h6:hover{
    /*font-size: 15px!important;*/
  }
  .line-item>div>i:nth-of-type(1){
    position: absolute;
    left: 20px;
    top: 35px;
    opacity: 0.5;
  }
  .line-item>div>i:nth-of-type(1).table-col{
    color: #3a7e3e!important;
  }
  .line-item>div>i:nth-of-type(1).line{
    color: #ab47bc!important;
  }
  .line-item>div>i:nth-of-type(1).bar{
    color: #009688!important;
  }
  .line-item>div>i:nth-of-type(1).pie{
    color: #1565c0!important;
  }
  .line-item>div>i:nth-of-type(1).base{
    color: #99cc55!important;
  }
  .line-item>div>i:nth-of-type(2){
    color: #000000!important;
    position: absolute;
    right: 15px;
    top: 35px;
  }
  .line-item>div>i:nth-of-type(2):hover{
    color: #f44336!important;
    /* border-radius: 50%;
    padding: 5px;
    background-color: #f44336!important;
    right: 10px;
    top: 25px; */
  }
  .cursor{
    cursor: pointer;
  }
  .cursor .v-chip__content{
    cursor: pointer;
  }
  .theme--light.v-chip {
    background: #555;
    color: #fff;
  }

  .theme--dark.v-chip {
    background: #e0e0e0;
    color: #000;
  }

  .v-chip.orange, .v-chip.red, .v-chip.blue {
    color: #fff;
  }

  .v-chip span {
    font-weight: bold!important;
    font-size: 0.75rem!important;
  }

  .v-chip-small .v-chip {
    margin-top: 3px!important;
    margin-bottom: -6px!important;
  }
  .v-chip-small .v-chip .v-chip__content {
    height: 20px;
    padding: 0 3px;
  }

  #checkTable .v-chip .v-chip__content,.checkTable .v-chip .v-chip__content {
    height: 15px;
    padding: 0 3px;
  }

  #checkTable #checkTableInput,.checkTable .checkTableInput {
    height: 15px;
  }
  .issue_tracking .v-input__control{
    position: relative;
  }
  .issue_tracking .v-input__control .v-text-field__details{
    /*display: none!important;*/
    position: absolute;
    top: -12px;
    right: 0;
  }
  .issue_tracking .issue_tracking_menu_chips .v-select__selections{
    min-height: auto!important;
  }
  .issue_tracking .issue_tracking_menu_chips .v-select__selections .v-chip{
    margin: 1px 4px!important;
  }
  .theme--dark .issue_tracking .issue_tracking_menu_chips.v-input--is-disabled .v-select__selections .v-chip{
    background: #aaaaaa!important;
    color: #000000!important;
  }
  .theme--light .issue_tracking .issue_tracking_menu_chips.v-input--is-disabled .v-select__selections .v-chip{
    background: #999999!important;
    color: #ffffff!important;
  }
  .issue_tracking .issue_tracking_menu_chips .v-select__selections .v-chip__content{
    height: auto!important;
  }
  .issue_tracking .issue_tracking_menu_chips .v-select__selections input{
    padding: 0!important;
  }
  .theme--dark .issue_tracking .v-input--is-disabled input[disabled='disabled']{
    color: #ffffff!important;
  }
  .theme--light .issue_tracking .v-input--is-disabled input[disabled='disabled']{
    color: #000000!important;
  }

  .theme--dark .v-date-picker-header .v-btn--icon .v-icon {
    color: #fff;
  }

  .theme--light .v-date-picker-header .v-btn--icon .v-icon {
    color: #000;
  }

  .theme--dark .v-date-picker-table button>.v-btn__content {
    color: #fff;
  }

  .theme--light .v-date-picker-table button>.v-btn__content {
    color: #000;
  }

  .theme--dark .v-date-picker-table button.v-btn--disabled>.v-btn__content {
    color: rgba(0,0,0,.26)!important;
  }

  .theme--light .v-date-picker-table button.v-btn--disabled>.v-btn__content {
    color: rgba(0,0,0,.26)!important;
  }

  .theme--dark.v-table tbody tr:nth-of-type(odd) {
    background: rgba(0, 0, 0, .2);
  }

  .theme--light.v-table tbody tr:nth-of-type(odd) {
    background: rgba(0, 0, 0, .1);
  }

  .theme--dark.v-table tbody tr:nth-of-type(odd):hover,.theme--dark.v-table tbody tr:nth-of-type(even):hover {
    background: rgba(255, 0, 0, .15)!important;
  }

  .theme--light.v-table tbody tr:nth-of-type(odd):hover,.theme--light.v-table tbody tr:nth-of-type(even):hover {
    background: rgba(0, 185, 255, .1)!important;
  }

  .theme--dark .v-date-picker-table tbody tr:nth-of-type(odd) {
    background: rgba(0, 0, 0, .2);
  }
  .v-datatable thead th.column.sortable .v-icon {
    font-size: 13px!important;
    margin-left: -2px!important;
  }

 .top5-reason-total{
   color: #ffff00;
   margin-left: 1rem;
 }
 .top5-reason-subtotal{
   color: #ffff00;
   margin-right: 1rem;
 }
 .top5-reason-description{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space : nowrap;
  display : inline-block;
  width : 350px;
  line-height: 1rem!important;
 }

 /* RGB */
  .data-area.rgb{
    padding: 10px;
    min-width: 250px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .data-area.rgb>.data-group{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: start;
    margin: 10px 0;
    width: 100%;
    background-color: yellowgreen;
    margin-right: 20px;
    justify-content: center;
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12);
  }
  .data-area.rgb>.data-group>.data-group-name{
    border-right: 2px grey solid;
    height: 100%;
    /* min-width: 100px;
    max-width: 150px; */
    width: 16%;
    text-align: center;
    font-size: 18px;
    font-weight: 900!important;
    border-radius: 3px;
    box-sizing: border-box;
    color: #ffffff;
    display: inline-block;
    font-family: system-ui, sans-serif;
    line-height: 1.125;
    padding: 10px 20px;
    margin: 10px;
    margin-right: 0;
    position: relative;
    vertical-align: middle;
    /*background-color: #7f8384;*/
    background-color: #000000;
    border-color: #575a5a;
    border-top-color: #848889;
    border-bottom-color: #393b3b;
    border-right: unset;
    box-shadow: 0 -0.125em 0 -0.063em #393b3b, 0 0.125em 0 -0.063em rgb(0 0 0 / 50%);
  }
  .data-area.rgb>.data-group>.data-group-name::before{
    content: '';
    position: absolute;
    top: 0px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: 4px;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left-color: #454848;
    border-right-color: #454848;
    border-bottom-color: #272929;
    box-shadow: 0 4px 4px -3px rgb(0 0 0 / 15%);
    background-image: linear-gradient(to right, #000000, #333333 5%, transparent 40%, transparent 60%, #333333 95%, #000000);
  }
  .data-area.rgb>.data-group>.data-set{
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: start;
    width: 84%;
  }
  .data-area.rgb>.data-group>.data-set>.data-box{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    border-right: 2px transparent solid;
    /* padding: 10px 20px; */
    padding: 10px 0;
    border-image: linear-gradient(to bottom, transparent 25%, grey 25%, grey 75%, transparent 75%);
    border-image-slice: 1;
    /* min-width: 120px; */
    width: 20%;
  }
  .data-area.rgb>.data-group>.data-set>.data-box:last-of-type{
    border-right: unset;
  }
  .data-area.rgb>.data-group>.data-set>.data-box>.data-item{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
  .data-area.rgb>.data-group>.data-set>.data-box>.data-item>.data-item-name{
    font-weight: 900!important;
    min-width: 40px;
    text-align: center;
    font-size: 18px;
    color: #000000;
  }
  .data-area.rgb>.data-group>.data-set>.data-box>.data-item>.data-item-value{
    font-size: 30px;
    font-weight: 900!important;
    padding: 0!important;
    color: green;
  }
  .data-area.rgb>.data-group>.data-set>.data-box>.data-item>.data-item-detail{
    font-size: 12px;
    font-weight: 900!important;
    padding: 0px 5px;
    min-width: 45px;
    text-align: center;
    display: inline-block;
  }
  .data-area.rgb>.data-group>.data-set>.data-box.ordinary>.data-item>.data-item-detail{
    background-color: green;
  }
  .data-area.rgb>.data-group>.data-set>.data-box.bad>.data-item>.data-item-detail{
    background-color: red;
  }
  .data-area.rgb>.data-group>.data-set>.data-box.bad>.data-item>.data-item-value{
    color: red;
  }

  /* Feeling */
  .data-area.feeling{
    padding: 10px;
    min-width: 250px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .data-area.feeling>.data-group{
    display: flex;
    flex-flow: row;
    align-items: start;
    justify-content: start;
    margin: 10px 0;
    width: 100%;
  }
  .data-area.feeling>.data-group>.data-group-name{
    min-width: 100px;
    text-align: center;
    font-weight: 900 !important;
    color: #ffffff;
    background: linear-gradient(-60deg, #111 20%, #222 35%, #333 50%, #282828 65%, #111 80%);
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12);
    border-right: 6px yellowgreen solid;
    padding: 10px 15px;
  }
  .data-area.feeling>.data-group>.data-group-name>span{
    color: yellowgreen;
    font-weight: 900 !important;
    margin-right: 10px;
    font-size: 24px !important;
  }
  .data-area.feeling>.data-group>.data-group-name>.cpk{
    cursor: pointer;
    color: #ffffff;
    font-size: 53px !important;
    line-height: 60px !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
    cursor: pointer;
  }
  .data-area.feeling>.data-group>.data-group-name>.lv{
    color: #ffffff;
    background-color: green;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-bottom: 0 !important;
    display: inline-block;
    padding: 2px 10px;
  }
  .data-area.feeling>.data-group>.data-set{
    display: flex;
    align-items: baseline;
    justify-content: start;
    flex-flow: column;
    width: calc(100% - 120px);
  }
  .data-area.feeling>.data-group>.data-set>.data-box{
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 0 10px;
    border-bottom: 2px yellowgreen dotted;
    justify-content: start;
    width: 100%;
  }
  .data-area.feeling>.data-group>.data-set>.data-box:last-of-type{
    border-bottom: unset;
  }
  .data-area.feeling>.data-group>.data-set>.data-box>.data-item{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
  }
  .data-area.feeling>.data-group>.data-set>.data-box>.data-item>.data-item-name{
    font-weight: 900!important;
    min-width: 40px;
    text-align: center;
    font-size: 14px;
    color: orange;
  }
  .data-area.feeling>.data-group>.data-set>.data-box>.data-item>.data-item-value{
    font-size: 30px;
    font-weight: 900!important;
    padding: 0 5px;
    color: #ffffff;
  }
  .data-area.feeling>.data-group>.data-set>.data-box>.data-item>.data-item-detail{
    font-size: 12px;
    font-weight: 900!important;
    padding: 0px 5px;
    min-width: 45px;
    text-align: left;
    display: inline-block;
    word-break: break-all;
  }
  .data-area.feeling>.data-group>.data-set>.data-box>.data-item>.data-item-detail>span{
    color: #ffffff;
    font-size: 13px;
    padding: 2px 5px;
    /*min-width: 42px;*/
    word-break: keep-all;
    display: inline-block;
  }
  .data-area.feeling>.data-group>.data-set>.data-box>.data-item>.data-item-detail>span:hover{
    cursor: pointer;
    font-weight: 900!important;
  }
  .data-area.feeling>.data-group>.data-set>.data-box>.data-item>.data-item-detail>span>a{
    color: #ffffff;
    font-size: 13px;
    /*min-width: 42px;*/
    display: inline-block;
  }
  .data-area.feeling>.data-group>.data-set>.data-box>.data-item>.data-item-detail>span>a:hover{
    cursor: pointer;
    font-weight: 900!important;
  }

   /* luminance */
  .data-area.luminance{
    padding: 10px;
    min-width: 250px;
    display: flex;
    flex-flow: row;
    align-items: center;
  }
  .data-area.luminance>.data-group{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: start;
    margin: 10px 0;
    /*margin-right: 20px;*/
    justify-content: center;
  }
  .data-area.luminance>.data-group>.data-group-name{
    border-right: 2px grey solid;
    height: 100%;
    min-width: 100px;
    text-align: center;
    font-size: 18px;
    font-weight: 900!important;
    border-radius: 3px;
    box-sizing: border-box;
    color: #ffffff;
    display: inline-block;
  }
  .data-area.luminance>.data-group>.data-set{
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: -20px;
  }
  .data-area.luminance>.data-group>.data-set>.data-box{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .data-area.luminance>.data-group>.data-set>.data-box:last-of-type{
    margin-right: 0;
  }
  .data-area.luminance>.data-group>.data-set>.data-box>.data-item{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 14px 10px;
    margin: 5px;
    margin-top: 0;
    background: linear-gradient(-60deg, #111 20%, #222 35%, #333 50%, #282828 65%, #111 80%);
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12);
  }
  .data-area.luminance>.data-group>.data-set>.data-box>.data-item>.data-item-name{
    font-weight: 900!important;
    text-align: center;
    font-size: 18px;
    color: yellowgreen;
  }
  .data-area.luminance>.data-group>.data-set>.data-box>.data-item>.data-item-value{
    font-size: 28px;
    font-weight: 900!important;
    padding: 8px 0;
    color: #ffffff;
  }
  .data-area.luminance>.data-group>.data-set>.data-box>.data-item>.data-item-value>.uniformity{
    font-size: 14px;
    font-weight: 700!important;
    margin-left: 5px;
    color: #ffffff;
  }
  .data-area.luminance>.data-group>.data-set>.data-box>.data-item>.data-item-detail{
    font-size: 12px;
    font-weight: 900!important;
    padding: 0px 5px;
    min-width: 45px;
    text-align: center;
    display: inline-block;
  }
  .data-area.luminance>.data-group>.data-set>.data-box.ordinary>.data-item>.data-item-detail{
    background-color: green;
  }
  .data-area.luminance>.data-group>.data-set>.data-box.bad>.data-item>.data-item-detail{
    background-color: red;
  }
  .data-area.luminance>.data-group>.data-set>.data-box.bad>.data-item>.data-item-value{
    color: red;
  }

 /* POL_ABI */
  .data-area.pol-abi{
    padding: 10px;
    min-width: 250px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .data-area.pol-abi>.data-group{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: start;
    margin: 10px 0;
    width: 100%;
    background: linear-gradient(-60deg, #111 20%, #222 35%, #333 50%, #282828 65%, #111 80%);
    margin-right: 20px;
    justify-content: center;
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12);
  }
  .data-area.pol-abi>.data-group>.data-group-name{
    height: 100%;
    min-width: 100px;
    max-width: 150px;
    text-align: center;
    font-size: 16px;
    font-weight: 700!important;
    border-radius: 3px;
    box-sizing: border-box;
    color: #333;
    display: inline-block;
    font-family: system-ui, sans-serif;
    line-height: 1.125;
    padding: 10px 20px;
    margin: 10px;
    margin-right: 0;
    position: relative;
    vertical-align: middle;
    /*background-color: #7f8384;*/
    background-color: yellowgreen;
  }
  .data-area.pol-abi>.data-group>.data-set{
    display: flex;
    flex-flow: row;
    align-items: baseline;
    justify-content: start;
  }
  .data-area.pol-abi>.data-group>.data-set>.data-box{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    border-right: 1px transparent solid;
    padding: 10px 20px;
    border-image: linear-gradient(to bottom, transparent 25%, white 25%, white 75%, transparent 75%);
    border-image-slice: 1;
    min-width: 120px;
  }
  .data-area.pol-abi>.data-group>.data-set>.data-box:last-of-type{
    border-right: unset;
  }
  .data-area.pol-abi>.data-group>.data-set>.data-box>.data-item{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
  .data-area.pol-abi>.data-group>.data-set>.data-box>.data-item>.data-item-name{
    font-weight: 900!important;
    min-width: 40px;
    text-align: center;
    font-size: 18px;
    color: yellowgreen;
  }
  .data-area.pol-abi>.data-group>.data-set>.data-box>.data-item>.data-item-value{
    font-size: 30px;
    font-weight: 900!important;
    padding: 0 5px;
    color: #ffffff;
  }
  .data-area.pol-abi>.data-group>.data-set>.data-box>.data-item>.data-item-detail{
    font-size: 12px;
    font-weight: 900!important;
    padding: 0px 5px;
    min-width: 45px;
    text-align: center;
    display: inline-block;
  }
  .data-area.pol-abi>.data-group>.data-set>.data-box.ordinary>.data-item>.data-item-detail{
    background-color: green;
  }
  .data-area.pol-abi>.data-group>.data-set>.data-box.bad>.data-item>.data-item-detail{
    background-color: red;
  }
  .data-area.pol-abi>.data-group>.data-set>.data-box.bad>.data-item>.data-item-value{
    color: red;
  }

 /* COG_bonding */
  .data-area.cog{
    padding: 10px;
    min-width: 250px;
    display: flex;
    flex-flow: row;
    align-items: center;
  }
  .data-area.cog.optics {
    align-items: baseline;
  }
  .data-area.cog>.data-group{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: start;
    margin: 10px 0;
    width: 100%;
    margin-right: 20px;
    justify-content: center;
  }
  .data-area.cog>.data-group:not(.main)>.data-group-name{
    height: 100%;
    text-align: right;
    font-size: 16px;
    font-weight: 500!important;
    box-sizing: border-box;
    display: inline-block;
    font-family: system-ui, sans-serif;
    line-height: 1.125;
    padding: 10px 20px;
    margin: 10px;
    position: relative;
    vertical-align: middle;
  }
  .theme--light  .data-area.cog>.data-group:not(.main)>.data-group-name{
    color: #000000;
  }
  .theme--dark  .data-area.cog>.data-group:not(.main)>.data-group-name{
    color: #ffffff;
  }
  .data-area.cog>.data-group:not(.main)>.data-set{
    width: 100%;
    height: 220px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    padding: 0px;
    border-top: 5px yellowgreen solid;
  }
  .data-area.cog>.data-group:not(.main)>.data-set>.data-box{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: start;
    padding-left: 10px;
    min-width: 120px;
    margin: 5px 0;
    height: calc(50% - 10px);
    width: 100%;
    position: relative;
    overflow: hidden;
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12);
    /*background: linear-gradient(-60deg, #111 20%, #222 35%, #333 50%, #282828 65%, #111 80%);*/
  }
  .data-area.cog>.data-group:not(.main)>.data-set>.data-box.x{
    background: linear-gradient(-60deg, rgb(14, 20, 37) 20%,  rgb(22, 28, 48) 35%, rgb(27, 31, 63) 50%, rgb(18, 30, 46) 65%, rgb(14, 20, 37) 80%);
  }
  .data-area.cog>.data-group:not(.main)>.data-set>.data-box.x::before{
    content: 'X';
    font-weight: 900!important;
    font-size: 70px;
    color: lightskyblue;
    position: absolute;
    right: -10px;
    top: -30px;
    opacity: 0.075;
  }
  .data-area.cog>.data-group:not(.main)>.data-set>.data-box.y{
    background: linear-gradient(-60deg, rgb(37, 14, 26) 20%,  rgb(48, 22, 35) 35%, rgb(63, 27, 45) 50%, rgb(46, 18, 32) 65%, rgb(37, 14, 26) 80%);
  }
  .data-area.cog>.data-group:not(.main)>.data-set>.data-box.y::before{
    content: 'Y';
    font-weight: 900!important;
    font-size: 70px;
    color: palevioletred;
    position: absolute;
    right: -8px;
    top: -30px;
    opacity: 0.075;
  }
  .data-area.cog>.data-group:not(.main)>.data-set>.data-box>.data-item{
    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: center;
  }
  .data-area.cog>.data-group:not(.main)>.data-set>.data-box>.data-item>.data-item-name{
    font-weight: 900!important;
    min-width: 40px;
    font-size: 18px;
    color: #ffffff;
    z-index: 1;
  }
  .data-area.cog>.data-group:not(.main)>.data-set>.data-box>.data-item>.data-item-name>span{
    font-weight: 900!important;
    min-width: 40px;
    font-size: 18px;
    color: yellowgreen;
    z-index: 1;
    margin-left: 5px;
  }
  .data-area.cog>.data-group:not(.main)>.data-set>.data-box>.data-item>.data-item-value{
    font-size: 40px;
    font-weight: 500!important;
    padding: 0 5px;
    color: #ffffff;
  }
  .data-area.cog>.data-group:not(.main)>.data-set>.data-box>.data-item>.data-item-value>.data-item-detail{
    font-size: 12px;
    font-weight: 900!important;
    padding: 0px 5px;
    width: 35%;
    text-align: right;
    display: inline-block;
    color: #ffffff;
    position: absolute;
    right: 0;
    bottom: 10px;
  }
  .data-area.cog>.data-group.main>.data-group-name{
    display: none;
  }
  .data-area.cog>.data-group.main>.data-set{
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: -20px;
  }
  .data-area.cog>.data-group.main>.data-set>.data-box{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
  }
  .data-area.cog>.data-group.main>.data-set>.data-box:last-of-type{
    margin-right: 0;
  }
  .data-area.cog>.data-group.main>.data-set>.data-box>.data-item{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    width: 100%;
    background: yellowgreen;
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12);
  }
  .data-area.cog.optics>.data-group.main>.data-set>.data-box>.data-item{
    padding: 5px 20px;
    align-items: flex-start;
    position: relative;
  }
  .data-area.cog>.data-group.main>.data-set>.data-box>.data-item>.data-item-name{
    font-weight: 700!important;
    text-align: center;
    font-size: 16px;
    color: #333333;
  }
  .data-area.cog>.data-group.main>.data-set>.data-box>.data-item>.data-item-name>span{
    font-weight: 900!important;
    text-align: center;
    font-size: 18px;
    color: #000000;
  }
  .data-area.cog>.data-group.main>.data-set>.data-box>.data-item>.data-item-value{
    font-size: 40px;
    line-height: 40px!important;
    font-weight: 900!important;
    padding: 0px;
    color: green;
  }
  .data-area.cog>.data-group.main>.data-set>.data-box>.data-item>.data-item-value>.data-item-detail{
    font-size: 12px;
    font-weight: 900!important;
    padding: 0px 5px;
    min-width: 45px;
    text-align: center;
    display: block;
    color: #ffffff;
  }
  .data-area.cog.optics>.data-group.main>.data-set>.data-box>.data-item>.data-item-value>.data-item-detail{
    font-size: 12px;
    font-weight: 900!important;
    padding: 0 5px;
    width: 35%;
    text-align: right;
    display: inline-block;
    color: #fff;
    position: absolute;
    right: 0;
    bottom: 10px;
  }
  .data-area.cog>.data-group>.data-set>.data-box.ordinary>.data-item>.data-item-value>.data-item-detail{
    background-color: green;
  }
  .data-area.cog>.data-group>.data-set>.data-box.bad>.data-item>.data-item-value>.data-item-detail{
    background-color: red;
  }
  .data-area.cog>.data-group>.data-set>.data-box.bad>.data-item>.data-item-value{
    color: red;
  }

 /* FOB_bonding */
  .data-area.fob{
    padding: 10px;
    min-width: 250px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .data-area.fob>.data-group{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: start;
    margin: 10px 0;
    width: 100%;
    margin-right: 20px;
    justify-content: center;
  }
  .data-area.fob>.data-group>.data-group-name{
    height: 100%;
    text-align: right;
    font-size: 16px;
    font-weight: 500!important;
    box-sizing: border-box;
    display: inline-block;
    font-family: system-ui, sans-serif;
    line-height: 1.125;
    padding: 10px 20px;
    margin: 10px;
    margin-right: 0;
    position: relative;
    vertical-align: middle;
  }
  .theme--light .data-area.fob>.data-group>.data-group-name{
    color: #000000;
  }
  .theme--dark .data-area.fob>.data-group>.data-group-name{
    color: #ffffff;
  }
  .data-area.fob>.data-group>.data-set{
    width: 100%;
    height: 220px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    padding: 0px;
    border-left: 5px yellowgreen solid;
  }
  .data-area.fob>.data-group>.data-set>.data-box{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: start;
    padding-left: 10px;
    min-width: 120px;
    margin: 5px;
    height: calc(50% - 10px);
    width: calc(50% - 10px);
    position: relative;
    overflow: hidden;
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12);
    /* background: linear-gradient(-60deg, #111 20%, #222 35%, #333 50%, #282828 65%, #111 80%); */
  }
  .data-area.fob>.data-group:not(.main)>.data-set>.data-box:nth-of-type(odd){
    background: linear-gradient(-60deg, rgb(14, 20, 37) 20%,  rgb(22, 28, 48) 35%, rgb(27, 31, 63) 50%, rgb(18, 30, 46) 65%, rgb(14, 20, 37) 80%);
  }
  .data-area.fob>.data-group>.data-set>.data-box:nth-of-type(odd)::before{
    content: 'X';
    font-weight: 900!important;
    font-size: 70px;
    color: lightskyblue;
    position: absolute;
    right: -10px;
    top: -30px;
    opacity: 0.075;
  }
  .data-area.fob>.data-group:not(.main)>.data-set>.data-box:nth-of-type(even){
    background: linear-gradient(-60deg, rgb(37, 14, 26) 20%,  rgb(48, 22, 35) 35%, rgb(63, 27, 45) 50%, rgb(46, 18, 32) 65%, rgb(37, 14, 26) 80%);
  }
  .data-area.fob>.data-group>.data-set>.data-box:nth-of-type(even)::before{
    content: 'Y';
    font-weight: 900!important;
    font-size: 70px;
    color: palevioletred;
    position: absolute;
    right: -8px;
    top: -30px;
    opacity: 0.075;
  }
  .data-area.fob>.data-group>.data-set>.data-box>.data-item{
    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: center;
  }
  .data-area.fob>.data-group>.data-set>.data-box>.data-item>.data-item-name{
    font-weight: 900!important;
    min-width: 40px;
    font-size: 18px;
    color: #ffffff;
    z-index: 1;
  }
  .data-area.fob>.data-group>.data-set>.data-box>.data-item>.data-item-name>span{
    font-weight: 900!important;
    min-width: 40px;
    font-size: 18px;
    color: yellowgreen;
    z-index: 1;
    margin-left: 5px;
  }
  .data-area.fob>.data-group>.data-set>.data-box>.data-item>.data-item-value{
    font-size: 40px;
    font-weight: 500!important;
    padding: 0 5px;
    color: #ffffff;
  }
  .data-area.fob>.data-group>.data-set>.data-box>.data-item>.data-item-value>.data-item-detail{
    font-size: 12px;
    font-weight: 900!important;
    padding: 0px 5px;
    width: 35%;
    text-align: right;
    display: inline-block;
    color: #ffffff;
    position: absolute;
    right: 0;
    bottom: 10px;
  }
  .data-area.fob>.data-group>.data-set>.data-box.ordinary>.data-item>.data-item-value>.data-item-detail{
    background-color: green;
  }
  .data-area.fob>.data-group>.data-set>.data-box.bad>.data-item>.data-item-value>.data-item-detail{
    background-color: red;
  }
  .data-area.fob>.data-group>.data-set>.data-box.bad>.data-item>.data-item-value{
    color: red;
  }

  /* BL_Assy */
  .data-area.bl-assy{
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .data-area.bl-assy>table{
    width: 100%;
    box-shadow: unset!important;
  }
  .data-area.bl-assy>table>tbody>tr{
    background: unset!important;
    border-top: none!important;
  }
  .data-area.bl-assy>table>tbody>tr>td{
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    width: 25%;

  }
  .data-area.bl-assy>table>tbody>tr>td.pwb>div{
    width: 300px;
    height: 150px;
    border: 10px #333333 double;
    background-color: rgba(104, 61, 61, 0.5);
    display: inline-flex;
    position: relative;
    justify-content: center;
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12);
  }
  .data-area.bl-assy>table>tbody>tr>td.pwb>div::after{
    content: 'PWB';
    position: absolute;
    bottom: -15px;
    background-color:#3a5f5b;
    color:#000000;
    width: 90%;
  }
  .data-area.bl-assy>table>tbody>tr>td>.data-item{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: start;
    position: relative;
    overflow: hidden;
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12);
  }
  .data-area.bl-assy>table>tbody>tr>td>.data-item.x{
    background: linear-gradient(-60deg, rgb(14, 20, 37) 20%,  rgb(22, 28, 48) 35%, rgb(27, 31, 63) 50%, rgb(18, 30, 46) 65%, rgb(14, 20, 37) 80%);
  }
  .data-area.bl-assy>table>tbody>tr>td>.data-item.x::before{
    content: 'X';
    font-weight: 900!important;
    font-size: 70px;
    color: lightskyblue;
    position: absolute;
    right: -10px;
    top: -30px;
    opacity: 0.075;
  }
  .data-area.bl-assy>table>tbody>tr>td>.data-item.y{
    background: linear-gradient(-60deg, rgb(37, 14, 26) 20%,  rgb(48, 22, 35) 35%, rgb(63, 27, 45) 50%, rgb(46, 18, 32) 65%, rgb(37, 14, 26) 80%);
  }
  .data-area.bl-assy>table>tbody>tr>td>.data-item.y::before{
    content: 'Y';
    font-weight: 900!important;
    font-size: 70px;
    color: palevioletred;
    position: absolute;
    right: -8px;
    top: -30px;
    opacity: 0.075;
  }
  .data-area.bl-assy>table>tbody>tr>td>.data-item>.title{
    font-weight: 900!important;
    min-width: 40px;
    font-size: 18px;
    color: #ffffff;
    z-index: 1;
    margin-left: 5px;
  }
  .data-area.bl-assy>table>tbody>tr>td>.data-item>.cpk{
    cursor: pointer;
    font-size: 40px!important;
    font-weight: 500!important;
    padding: 0 5px;
    color: #ffffff;
  }
  .data-area.bl-assy>table>tbody>tr>td>.data-item>.lv{
    font-size: 12px!important;
    font-weight: 900!important;
    padding: 0px 5px;
    width: 35%;
    text-align: right;
    display: inline-block;
    color: #ffffff;
    position: absolute;
    right: 0;
    bottom: 10px;
  }
  .data-area.bl-assy>table>tbody>tr>td>.data-item.ordinary>.lv{
    background-color: green;
  }
  .data-area.bl-assy>table>tbody>tr>td>.data-item.bad>.lv{
    background-color: red;
  }
  .data-area.bl-assy>table>tbody>tr>td>.data-item.bad>.cpk{
    color: red;
  }
  .data-area.bl-assy>table>tbody>tr>td>.data-item.nodata>.cpk{
    color: #444;
  }
  /* Key_Size */
  .data-area.key-size{
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .data-area.key-size>.data-group{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: start;
    margin: 10px 0;
    width: 100%;
  }
  .data-area.key-size>.data-group>.data-group-name{
    text-align: center;
    font-weight: 900 !important;
    color: #ffffff;
    background: linear-gradient(-60deg, #111 20%, #222 35%, #333 50%, #282828 65%, #111 80%);
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12);
    padding: 10px 15px;
    min-width: 150px;
  }
  .data-area.key-size>.data-group>.data-group-name>span{
    color: yellowgreen;
    font-weight: 900 !important;
    margin-right: 10px;
  }
  .data-area.key-size>.data-group>.data-group-name>.cpk{
    cursor: pointer;
    font-size: 40px!important;
    margin-bottom: 5px!important;
    font-weight: 500!important;
    padding: 0 5px;
    color: #ffffff;
  }
  .data-area.key-size>.data-group>.data-group-name>.lv{
    font-size: 12px!important;
    font-weight: 900!important;
    padding: 0px 5px;
    color: #ffffff;
    display: inline-block;
  }
  .data-area.key-size>.data-group>.data-group-name.ordinary>.lv{
    background-color: green;
  }
  .data-area.key-size>.data-group>.data-group-name.bad>.lv{
    background-color: red;
  }
  .data-area.key-size>.data-group>.data-group-name.bad>.cpk{
    color: red;
  }

  /* no data */
  .data-area.no-data{
    padding: 25px 10px;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .data-area.no-data-page{
    padding: 25px 10px;
    margin: 0 12px;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 22px !important;
    font-weight: bold!important;
    letter-spacing: 3px;
    background-image: repeating-linear-gradient(-45deg,#ffaa00 0%, #ffaa00 12.5%, #ffc400 12.5%, #ffc400 25%, #ffaa00 25%, #ffaa00 37.5%, #ffc400 37.5%, #ffc400 50%);
    /*background-image: repeating-linear-gradient(-45deg,#ffaa00 0%, #ffaa00 2.5%, #ffc400 2.5%, #ffc400 5%, #ffaa00 5%, #ffaa00 7.5%, #ffc400 7.5%, #ffc400 10%);*/
    /*animation: gradientChange 0s infinite linear;*/
  }
  /* @keyframes gradientChange  {
    0% {
      background-image: repeating-linear-gradient(-45deg,#ffaa00 0%, #ffaa00 2.5%, #ffc400 2.5%, #ffc400 5%, #ffaa00 5%, #ffaa00 7.5%, #ffc400 7.5%, #ffc400 10%);
    }
    25% {
      background-image: repeating-linear-gradient(-45deg,#ffaa00 1.25%, #ffaa00 3.75%, #ffc400 3.75%, #ffc400 6.25%, #ffaa00 6.25%, #ffaa00 8.75%, #ffc400 8.75%, #ffc400 11.25%);
    }
    50% {
      background-image: repeating-linear-gradient(-45deg,#ffaa00 2.5%, #ffaa00 5%, #ffc400 5%, #ffc400 7.5%, #ffaa00 7.5%, #ffaa00 10%, #ffc400 10%, #ffc400 12.5%);
    }
    100% {
      background-image: repeating-linear-gradient(-45deg,#ffaa00 3.75%, #ffaa00 6.25%, #ffc400 6.25%, #ffc400 8.75%, #ffaa00 8.75%, #ffaa00 11.25%, #ffc400 11.25%, #ffc400 13.75%);
    }
  } */
  /* fail Row */
  .fail-data-row{
    display: flex;
    flex-flow: row wrap;
    align-items: start;
    justify-content: start;
    padding: 10px 0;
  }
  .fail-data-row:not(:first-of-type){
    border-top: 1px #666 solid;
  }
  .fail-data-row>.fail-data-title{
    font-weight: 900!important;
    width: 95px;
    font-size: 14px;
    color: orange;
  }
  .fail-data-row>.fail-data-title>p{
    font-weight: 900!important;
    width: 95px;
    word-break: break-word;
    text-align: justify;
    font-size: 18px!important;
    color: #ffffff;
    margin: 0 !important;
  }
  .fail-data-row>.fail-data-key{
    margin-left: 5px;
    display: flex;
    flex-flow: wrap;
    width: calc(100% - 100px);
  }
  .fail-data-row>.fail-data-key>span{
    margin: 0 3px;
    font-size: 15px!important;
    min-width: calc((100% - 48px) / 8);
  }

  /* fail */
  .fail-data-area{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: start;
    padding: 10px 0;
  }
  .fail-data-area>div{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: start;
    width: calc(50% - 10px);
    padding: 10px;
    margin: 5px;
    /*background: rgba(0,0,0,0.75);*/
    background: linear-gradient(-60deg, #111 20%, #222 35%, #333 50%, #282828 65%, #111 80%);
  }
  .fail-data-area.lcd>div{
    width: calc(100% - 10px);
  }
  .fail-data-area>div>p{
    font-size: 20px!important;
    font-weight: 900!important;
    margin: 0!important;
    padding-right: 10px;
    min-width: 100px;
    text-align: right;
  }
  .fail-data-area.lcd>div>p{
    min-width: 160px;
    color: white;
  }
  .fail-data-area>div>div{
    border-left: 3px yellowgreen solid;
    padding-left: 5px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: start;
    width: 100%;
  }
  .fail-data-area>div>div>span{
    color: white;
    cursor: pointer;
    font-size: 18px;
    margin: 0 10px;
    width: 100%;
  }
  .fail-data-area.lcd>div>div>span>strong{
    color: #000;
    background-color: yellowgreen;
    padding: 0 10px;
    font-weight: 900!important;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
    min-width: 30px;
    display: inline-flex;
    line-height: 20px;
  }
  .fail-data-area.lcd>div>div>span>span{
    color: red;
    font-weight: 900!important;
    cursor: pointer;
    font-size: 18px;
    margin: 0 10px;
    width: 100%;
  }
  .fail-data-area>div>div>span:hover{
    font-weight: 900!important;
  }
  /* error msg */
  .selenium-catch-error{
    /**/
  }
  /* .data-area>.data-group.luminance>.data-set>.data-box{
    border: 5px yellowgreen solid!important
  }
  .data-area>.data-group.luminance>.data-set>.data-box>.data-item>.data-item-detail{
    width: 100%;
  }
  .data-area>.data-group.luminance>.data-set>.data-box>.data-item>.data-item-detail span{
    display: block;
  } */
  .keypart-table th {
    vertical-align: bottom;
  }
  .keypart-table th span{
    font-weight: bold!important;
  }
  .keypart-table th span .sort-btn{
    font-size: 13px!important;
    opacity: 0;
    transform:rotate(0deg);
    transition: 0.2s;
  }
  .keypart-table th:hover span .sort-btn{
    opacity: 0.5;
  }
  .keypart-table th.asc span .sort-btn{
    opacity: 1;
  }
  .keypart-table th.desc span .sort-btn{
    opacity: 1;
    transform:rotate(180deg);
  }
  .theme--dark .keypart-table tr th.data-group-name {
    text-align: center;
  }
  .theme--dark .keypart-table tr th.data-group-name:nth-of-type(even) {
    background: rgba(0, 0, 0, .2);
  }
  .table-filter-setting-bar input{
    text-align: center;
  }
  .table-filter-setting-bar .v-input:nth-of-type(2) input{
    color: #ffffff;
    font-weight: 900;
  }
  .table-filter-setting-bar .v-input:nth-of-type(2) .v-input__slot:before{
    display: none;
  }
  .table-filter-setting-bar .v-btn-toggle--selected{
    box-shadow: unset;
  }
  .table-filter-item{
    color: pink;
    font-size: 13px!important;
    padding: 3px;
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
  }
  .table-filter-item i{
    color: pink!important;
    font-size: 13px!important;
    line-height: 14px;
    margin-right: 5px;
  }
  .table-filter-item i.close{
    color: #ffffff!important;
    background-color: red;
    padding: 0 5px;
    border-radius: 10px;
    margin-left: 5px;
  }
  .keypart-chart-info{
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 10px 0;
    background: linear-gradient(-60deg, #111 20%, #222 35%, #333 50%, #282828 65%, #111 80%);
    box-shadow: 0 6px 6px -3px rgb(0 0 0 / 20%), 0 10px 14px 1px rgb(0 0 0 / 14%), 0 4px 18px 3px rgb(0 0 0 / 12%);
    margin-bottom: 20px;
  }
  .keypart-chart-info>div{
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    padding: 0 10px;
  }

  .keypart-chart-info>div:nth-of-type(2){
    min-width: 150px;
  }
  .keypart-chart-info>div:not(:last-of-type){
    border-right: 1px grey solid;
  }
  .keypart-chart-info>div>span{
    font-size: 18px!important;
    font-weight: 900!important;
    color: yellowgreen;
  }
  .keypart-chart-info>div>p{
    font-size: 36px!important;
    font-weight: 300!important;
  }
  .keypart-chart-info>div>div{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  .keypart-chart-info>div>div>span{
    font-size: 14px!important;
    font-weight: 500!important;
  }
  .keypart-chart-info .ordinary>div>span{
    background-color: green;
    width: 100%;
    text-align: center;
  }
  .keypart-chart-info .bad>p{
    color: red;
    font-weight: 500!important;
  }
  .keypart-chart-info .bad>div>span{
    background-color: red;
    color: #ffffff;
    width: 100%;
    text-align: center;
  }
  .keypart-chart-area .c3-axis .c3-axis-y-label{
    stroke: orange!important;
    font-weight: 300!important;
    font-size: 15px!important;
  }
  .keypart-chart-area .c3-axis .c3-axis-y2-label{
    stroke: orange!important;
    font-weight: 300!important;
    font-size: 15px!important;
  }
  .small-cpk-area .v-card__text{
    display: flex;
    flex-flow: row;
  }
  .small-cpk-area .v-card__text>div{
    width: 300px;
  }
  .small-cpk-info{
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 10px 0;
    background: linear-gradient(-60deg, #111 20%, #222 35%, #333 50%, #282828 65%, #111 80%);
    box-shadow: 0 6px 6px -3px rgb(0 0 0 / 20%), 0 10px 14px 1px rgb(0 0 0 / 14%), 0 4px 18px 3px rgb(0 0 0 / 12%);
    margin-top: 20px;
  }
  .small-cpk-info>div{
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    padding: 0 10px;
  }
  .small-cpk-info>div:nth-of-type(2){
    min-width: 150px;
  }
  .small-cpk-info>div:not(:last-of-type){
    border-right: 1px grey solid;
  }
  .small-cpk-info>div>span{
    font-size: 14px!important;
    font-weight: 900!important;
    color: yellowgreen;
  }
  .small-cpk-info>div>p{
    font-size: 22px!important;
    font-weight: 300!important;
    margin-bottom: 0!important;
  }
  .small-cpk-info>div>div{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  .small-cpk-info>div>div>span{
    font-size: 12px!important;
    font-weight: 500!important;
  }
  .small-cpk-info .ordinary>div>span{
    background-color: green;
    width: 100%;
    text-align: center;
  }
  .small-cpk-info .bad>p{
    color: red;
    font-weight: 500!important;
  }
  .small-cpk-info .bad>div>span{
    background-color: red;
    color: #ffffff;
    width: 100%;
    text-align: center;
  }
  .small-cpk-table{
    margin: 0!important;
  }
  .small-cpk-chart .c3-axis .c3-axis-y-label{
    stroke: orange!important;
    font-weight: 100!important;
    font-size: 15px!important;
  }
  .small-cpk-chart .c3-axis .c3-axis-y2-label{
    stroke: orange!important;
    font-weight: 100!important;
    font-size: 15px!important;
  }

  .station-name{
    width: calc(50% - 10px);
    display: inline-block;
    background: rgba(0, 0, 0, .2);
    margin: 5px;
    padding: 10px;
    border-left: 10px darkolivegreen solid;
  }
  .station-name>i{
    font-size: 15px;
  }
  .c3-grid.c3-grid-lines .c3-xgrid-lines .c3-xgrid-line:nth-child(1) line{
    stroke: yellowgreen!important;
  }
  .c3-grid.c3-grid-lines .c3-xgrid-lines .c3-xgrid-line:nth-child(2) line{
    stroke: yellowgreen!important;
  }
  /* .table-group-name{
    margin: 10px 0;
    margin-bottom: 5px;
    height: 24px;
    position: relative;
  }
  .table-group-name>span{
    color: #ff9800!important;
    font-weight: bold!important;
    word-break: keep-all;
    white-space: normal;
    font-size: 16px!important;
    display: none;
    position: absolute;
    padding-right: 30px;
    border-bottom: 1px #ff9800 solid;
  }
  table>thead>tr>th:nth-of-type(5n+2)>.table-group-name>span {
    display: block;
    width: max-content;
  } */
  /*
  pre {
    overflow-wrap: normal;
    white-space: pre;
  }
  */
  .weeks-list-data-tip{
    overflow: unset !important;
  }
  .weeks-list-data-tip .v-list__tile__title{
    overflow: unset !important;
  }
  .weeks-list-data-tip .v-list__tile__title::before{
    content: '';
    width: 8px;
    height: 8px;
    background-color: #4caf50;
    border-radius: 50%;
    position: absolute;
    left: -12px;
    top: 8px
  }

  .timeline{
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 30px 0;
    width: 100%;
    display: block;
  }
  .timeline>.timeline-item{
    display: flex;
    flex-flow: column;
    margin: 0;
    padding: 0;
    padding-bottom: 40px;
    position: relative;
    overflow: hidden;
  }
  .timeline>.timeline-item.fail {
    justify-content: start;
    align-items: end;
  }
  .timeline>.timeline-item.pass {
    justify-content: start;
    align-items: start;
  }
  .timeline>.timeline-item:last-child {
    padding-bottom: 80px;
  }
  .timeline>.timeline-item>.timeline-info{
    display: block;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .timeline>.timeline-item>.timeline-marker{
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(30% - 8px);
    width: 16px;
    margin-left: -7.5px;
    display: block;
    margin: 0;
    padding: 0;
  }
  .timeline>.timeline-item>.timeline-marker::before{
    border: 3px solid transparent;
    border-radius: 100%;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    top: 4px;
    left: 0;
    width: 15px;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
  }
  .timeline>.timeline-item>.timeline-marker::after{
    content: "";
    width: 3px;
    background: #CCD5DB;
    display: block;
    position: absolute;
    top: 24px;
    bottom: 0;
    left: 6px;
  }
  .timeline>.timeline-item:last-child>.timeline-marker::after{
    /* content: none; */
    background: transparent;
    background-image: linear-gradient(to bottom, #CCD5DB, transparent);
  }
  .timeline>.timeline-item:not(.period):hover>.timeline-marker::before {
    background: transparent;
  }
  .timeline>.timeline-item>.timeline-content{
    display: block;
    margin: 0;
    padding: 0;
  }
  .timeline>.timeline-item:not(.period)>.timeline-content>.timeline-title {
    line-height: 10px !important;
    margin-bottom: 0 !important;
  }
  .timeline>.timeline-item:not(.period)>.timeline-content>.timeline-title>span {
    font-size: 25px;
    font-weight: 900 !important;
    cursor: pointer;
  }
  .timeline>.timeline-item>.timeline-content>p:last-child {
    margin-bottom: 0;
  }
  .timeline>.timeline-item.period {
    display: block;
    padding: 0;
  }
  .timeline>.timeline-item.period>.timeline-info {
    display: none;
  }
  .timeline>.timeline-item.period>.timeline-marker::before {
    background: transparent;
    content: "";
    width: 15px;
    height: auto;
    border: none;
    border-radius: 0;
    top: 0;
    bottom: 30px;
    position: absolute;
    /* border-top: 3px solid #CCD5DB;
    border-bottom: 3px solid #CCD5DB; */
  }
  .timeline>.timeline-item.period>.timeline-marker::after {
    content: "";
    height: 20px;
    top: auto;
  }
  .timeline>.timeline-item.period>.timeline-content {
    padding-top: 5px;
    width: 60%;
    padding-bottom: 25px;
    text-align: center;
  }
  .timeline>.timeline-item.period>.timeline-content>.timeline-title {
    padding: 5px 10px;
    text-align: center;
  }
  .timeline>.timeline-item.period>.timeline-content>.timeline-title>span {
    font-size: 18px !important;
    font-weight: 900 !important;
  }
  .timeline>.timeline-item.pass>.timeline-info {
    text-align: right;
    padding: 0 20px;
    padding-bottom: 0;
    width: calc(30% - 30px);
    border-radius: 20px 0 0 0;
    background: #00700033;
    transition: 0.25s;
  }
  .timeline>.timeline-item.pass:hover>.timeline-info {
    background: #007000;
  }
  .timeline>.timeline-item.pass>.timeline-content {
    text-align: right;
    padding: 5px 20px;
    padding-top: 0;
    width: calc(30% - 30px);
    border-radius: 0 0 20px 20px;
    background: #00700033;
    transition: 0.25s;
  }
  .timeline>.timeline-item.pass:hover>.timeline-content {
    background: #007000;
  }
  .timeline>.timeline-item.pass>.timeline-marker::before{
    background: #007000;
  }
  .timeline>.timeline-item.pass:hover>.timeline-marker::before {
    border: 3px solid #007000;
  }
  .timeline>.timeline-item.fail>.timeline-info {
    text-align: left;
    padding: 0 20px;
    padding-bottom: 0;
    width: calc(70% - 30px);
    border-radius: 0 20px 0 0;
    background: #c73a3a33;
    transition: 0.25s;
  }
  .timeline>.timeline-item.fail:hover>.timeline-info {
    background: #c73a3a;
  }
  .timeline>.timeline-item.fail>.timeline-content {
    text-align: left;
    padding: 5px 20px;
    padding-top: 0;
    width: calc(70% - 30px);
    border-radius: 0 0 20px 20px;
    background: #c73a3a33;
    transition: 0.25s;
  }
  .timeline>.timeline-item.fail:hover>.timeline-content {
    background: #c73a3a;
  }
  .timeline>.timeline-item.fail>.timeline-content>p{
    text-align: justify;
  }
  .timeline>.timeline-item.fail>.timeline-marker::before{
    background: #c73a3a;
  }
  .timeline>.timeline-item.fail:hover>.timeline-marker::before {
    border: 3px solid #c73a3a;
  }

  .yellowgreen-2{
    background-color: #8acb23!important;
    border-color: #8acb23!important;
    color: #000000!important;
  }
  .yellowgreen-3{
    background-color: #a2cb23!important;
    border-color: #a2cb23!important;
    color: #000000!important;
  }
  .yellowgreen-4{
    background-color: #c9cb23!important;
    border-color: #c9cb23!important;
    color: #000000!important;
  }
  .yellow-1{
    background-color: #ffe600!important;
    border-color: #ffe600!important;
    color: #000000!important;
  }
  .yellow-2{
    background-color: #ffd000!important;
    border-color: #ffd000!important;
    color: #000000!important;
  }
  .yellow-3{
    background-color: #eca200!important;
    border-color: #eca200!important;
  }
  .yellow-4{
    background-color: #ff8800!important;
    border-color: #ff8800!important;
  }
  .orange-1{
    background-color: #ef6c00!important;
    border-color: #ef6c00!important;
  }
  .orange-2{
    background-color: #d34401!important;
    border-color: #d34401!important;
  }
  .orange-3{
    background-color: #e53935!important;
    border-color: #e53935!important;
  }
  .orange-4{
    background-color: #c62828!important;
    border-color: #c62828!important;
  }
  .red-1{
    background-color: #940d0d!important;
    border-color: #940d0d!important;
  }
  .red-2{
    background-color: #97052d!important;
    border-color: #97052d!important;
  }
  .red-3{
    background-color: #b91248!important;
    border-color: #b91248!important;
  }
  .red-4{
    background-color: #8f0a41!important;
    border-color: #8f0a41!important;
  }
  .pink-1{
    background-color: #970571!important;
    border-color: #970571!important;
  }

  .table-width-25 {
    /* width: 25px!important; */
    min-width: 25px!important;
    /* max-width: 25px!important; */
  }
  .table-width-50 {
    /* width: 50px!important; */
    min-width: 50px!important;
    /* max-width: 50px!important; */
  }
  .table-width-55 {
    /* width: 55px!important; */
    min-width: 55px!important;
    /* max-width: 55px!important; */
  }
  .table-width-75 {
    /* width: 75px!important; */
    min-width: 75px!important;
    /* max-width: 75px!important; */
  }
  .freeze-width-75 {
    width: 75px!important;
    min-width: 75px!important;
    max-width: 75px!important;
  }
  .table-width-90 {
    /* width: 90px!important; */
    min-width: 90px!important;
    /* max-width: 90px!important; */
  }
  .freeze-width-90 {
    width: 90px!important;
    min-width: 90px!important;
    max-width: 90px!important;
  }
  .table-width-100 {
    /* width: 100px!important; */
    min-width: 100px!important;
    /* max-width: 100px!important; */
  }
  .table-width-105 {
    /* width: 105px!important; */
    min-width: 105px!important;
    /* max-width: 105px!important; */
  }
  .table-width-110 {
    /* width: 110px!important; */
    min-width: 110px!important;
    /* max-width: 110px!important; */
  }
  .table-width-125 {
    /* width: 125px!important; */
    min-width: 125px!important;
    /* max-width: 125px!important; */
  }
  .table-width-135 {
    /* width: 135px!important; */
    min-width: 135px!important;
    /* max-width: 135px!important; */
  }
  .table-width-150 {
    /* width: 150px!important; */
    min-width: 150px!important;
    /* max-width: 150px!important; */
  }
  .freeze-width-150 {
    width: 150px!important;
    min-width: 150px!important;
    max-width: 150px!important;
  }
  .table-width-175 {
    /* width: 175px!important; */
    min-width: 175px!important;
    /* max-width: 175px!important; */
  }
  .table-width-185 {
    /* width: 185px!important; */
    min-width: 185px!important;
    /* max-width: 185px!important; */
  }
  .table-width-200 {
    /* width: 200px!important; */
    min-width: 200px!important;
    /* max-width: 200px!important; */
  }
  .freeze-width-200 {
    width: 200px!important;
    min-width: 200px!important;
    max-width: 200px!important;
  }
  .table-width-225 {
    /* width: 225px!important; */
    min-width: 225px!important;
    /* max-width: 225px!important; */
  }
  .table-width-250 {
    /* width: 250px!important; */
    min-width: 250px!important;
    /* max-width: 250px!important; */
  }
  .table-width-275 {
    /* width: 275px!important; */
    min-width: 275px!important;
    /* max-width: 275px!important; */
  }
  .table-width-300 {
    /* width: 300px!important; */
    min-width: 300px!important;
    /* max-width: 300px!important; */
  }
  .table-width-350 {
    /* width: 350px!important; */
    min-width: 350px!important;
    /* max-width: 350px!important; */
  }
  .table-width-400 {
    /* width: 400px!important; */
    min-width: 400px!important;
    /* max-width: 400px!important; */
  }
  .table-width-450 {
    /* width: 450px!important; */
    min-width: 450px!important;
    /* max-width: 450px!important; */
  }
  .table-width-500 {
    /* width: 500px!important; */
    min-width: 500px!important;
    /* max-width: 500px!important; */
  }

  .row-btn-50 {
    width: calc(100% - 50px);
    display: inline-block;
  }
  .row-btn-75 {
    width: calc(100% - 75px);
    display: inline-block;
  }
  .row-btn-100 {
    width: calc(100% - 100px);
    display: inline-block;
  }
  .row-btn-125 {
    width: calc(100% - 125px);
    display: inline-block;
  }
  .row-btn-150 {
    width: calc(100% - 150px);
    display: inline-block;
  }
  .row-btn-175 {
    width: calc(100% - 175px);
    display: inline-block;
  }
  .row-btn-200 {
    width: calc(100% - 200px);
    display: inline-block;
  }
  .row-btn-225 {
    width: calc(100% - 225px);
    display: inline-block;
  }
  .row-btn-250 {
    width: calc(100% - 250px);
    display: inline-block;
  }
  .row-btn-275 {
    width: calc(100% - 275px);
    display: inline-block;
  }
  .row-btn-300 {
    width: calc(100% - 300px);
    display: inline-block;
  }
  .row-btn-325 {
    width: calc(100% - 325px);
    display: inline-block;
  }
  .row-btn-350 {
    width: calc(100% - 350px);
    display: inline-block;
  }
  .row-btn-375 {
    width: calc(100% - 375px);
    display: inline-block;
  }
  .row-btn-400 {
    width: calc(100% - 400px);
    display: inline-block;
  }
  .row-btn-450 {
    width: calc(100% - 450px);
    display: inline-block;
  }
  .row-btn-500 {
    width: calc(100% - 500px);
    display: inline-block;
  }

  .min-height-100 {
    min-height: 100px;
    overflow-y: auto;
  }
  .min-height-200 {
    min-height: 200px;
    overflow-y: auto;
  }
  .min-height-300 {
    min-height: 300px;
    overflow-y: auto;
  }
  .min-height-400 {
    min-height: 400px;
    overflow-y: auto;
  }
  .min-height-500 {
    min-height: 500px;
    overflow-y: auto;
  }
  .min-height-600 {
    min-height: 600px;
    overflow-y: auto;
  }

  .max-height-100 {
    max-height: 100px;
    overflow-y: auto;
  }
  .max-height-200 {
    max-height: 200px;
    overflow-y: auto;
  }
  .max-height-300 {
    max-height: 300px;
    overflow-y: auto;
  }
  .max-height-400 {
    max-height: 400px;
    overflow-y: auto;
  }
  .max-height-500 {
    max-height: 500px;
    overflow-y: auto;
  }
  .max-height-600 {
    max-height: 600px;
    overflow-y: auto;
  }
  /* IE10+ CSS styles go here */
_:-ms-lang(x), .v-dialog__content>.v-dialog {
    top: calc((100vh - 700px) / 2)!important;
  }

/* _:-ms-lang(x), .ie-btn-mt--3 {
    margin-top: -3rem!important;
  } */

_:-ms-lang(x), table.v-table tbody td button.small, table.v-table thead th button.small {
    font-size: 0.75rem!important;
  }

_:-ms-lang(x), table.v-table tbody td button.small i, table.v-table thead th button.small i {
    font-size: 0.75rem!important;
  }

/* width */
table::-webkit-scrollbar,table ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* Track */
table::-webkit-scrollbar-track,table ::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
.theme--light table::-webkit-scrollbar-thumb,.theme--light table ::-webkit-scrollbar-thumb {
  background: #1e88e5;
}
/* Handle */
.theme--dark table::-webkit-scrollbar-thumb,.theme--dark table ::-webkit-scrollbar-thumb {
  background: #f44336;
}
/* Handle on hover */
.theme--light table::-webkit-scrollbar-thumb:hover,.theme--light table ::-webkit-scrollbar-thumb:hover {
  background: #1e88e5;
}
/* Handle on hover */
.theme--dark table::-webkit-scrollbar-thumb:hover,.theme--dark table ::-webkit-scrollbar-thumb:hover {
  background: #f44336;
}

/* width */
aside ::-webkit-scrollbar {
  width: 3px;
}
/* Track */
aside ::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
aside.theme--light ::-webkit-scrollbar-thumb {
  background: #1e88e599;
}
/* Handle */
aside.theme--dark ::-webkit-scrollbar-thumb {
  background: #f4433655;
}
/* Handle on hover */
aside.theme--light ::-webkit-scrollbar-thumb:hover {
  background: #1e88e599;
}
/* Handle on hover */
aside.theme--dark ::-webkit-scrollbar-thumb:hover {
  background: #f4433655;
}
</style>
